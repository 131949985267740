import React from "react";
import SelectedX from "../../icons/SelectedX";

const ItemsSelected = ({ className, selectedFoldersLength, textSize }) => {
  return (
    <div>
      <button className={`border rounded border-dark5 ${className} w-full py-[8px] px-[10px] flex flex-row justify-center gap-2`}>
        <SelectedX className="pt-1" />
        <p className={`text-bodyMR text-dark5 ${textSize}`}>{selectedFoldersLength} selected</p>
      </button>
    </div>
  );
};

export default ItemsSelected;
