import React, { useEffect, useState } from "react";
import EmptyFolder from "../../../icons/EmptyFolder";
import DotsIcon from "../../../icons/DotsIcon";
import { ToastContainer } from "react-toastify";
import DocumentIcon from "../../../icons/DocumentIcon";
import ImageIcon from "../../../icons/ImageIcon";
import MovieIcon from "../../../icons/MovieIcon";
import ZipDir from "../../../icons/ZipDir";
import { Hourglass } from "react-loader-spinner";
import FullFolder from "../../../icons/FullFolder";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const ContentFiles = ({
                        folderStructure,
                        selectedFolders,
                        handleCheckboxChange,
                        getExtension}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center">
        <Hourglass
          visible={true}
          height={40}
          width={40}
          animationDuration={3000}
          ariaLabel="hourglass-loading"
          wrapperStyle={{}}
          wrapperClass=""
          colors={["#306cce", "#72a1ed"]}
        />
      </div>
    );
  }

  const removeSlash = (name) => {
    return name.replace(/\//g, "");
  };

  const handleNavigateFolderContent = (name) => {
    const formattedName = name.replace("/", "");
    navigate(`${location.pathname}/${formattedName}`);
  };

  const handleFolderStructure = (obj, path) => {
    const parts = path.split("/").filter(Boolean); // Split the path and filter out empty parts
    let current = obj;
    for (const part of parts) {
      const path = part + "/";
      if (current[path] !== undefined) {
        current = current[path];
      } else {
        return undefined; // Path does not exist
      }
    }
    return current;
  };

  const currentFolder = handleFolderStructure(folderStructure, params["*"]);
  const files = currentFolder ? Object.entries(currentFolder).filter(([name, info]) => !info.isDirectory) : [];
  const folders = currentFolder ? Object.entries(currentFolder).filter(([name, info]) => info.isDirectory) : [];
  return (
    <div className="content-files px-6 py-6 space-y-9 cursor-pointer">
      <div className="folders space-y-4">
        <h6 className="text-bodyLR text-dark4">Folders</h6>
        <div className="content-folders flex mobile:flex-col desktop:flex-row desktop:space-x-6 mobile:space-y-4 desktop:items-center cursor-pointer">
          {folders.length > 0 ? (
            folders.map(
              ([folderName, folderInfo]) => (
                <div key={folderName}
                     className={`folder-item ml-0 flex flex-row mobile:justify-between mobile:items-center`}>
                  <div
                    className={`flex flex-row relative desktop:flex-col items-center desktop:border  ${
                      selectedFolders.includes(folderName) ? "desktop:bg-blueLight5" : "desktop:bg-white"
                    } desktop:rounded-lg desktop:p-4 desktop:hover:shadow-shadow5  group`}
                  >
                    <label htmlFor={`folder-checkbox-${folderName}`}>
                      <input
                        type="checkbox"
                        id={`folder-checkbox-${folderName}`}
                        className={`rounded-lg absolute  right-2 top-2   opacity-0 ${
                          selectedFolders.includes(folderName) ? "opacity-100" : ""
                        } mobile:hidden group-hover:opacity-100`}
                        checked={selectedFolders.includes(folderName)}
                        onChange={(e) => handleCheckboxChange(folderName, e.target.checked)}
                      />
                    </label>
                    <div className={`mobile:flex mobile:items-center space-x-2`}
                         onClick={() => handleNavigateFolderContent(folderName)}>
                      {folderInfo.hasFiles ? (
                        <>
                          <FullFolder className="mobile:hidden cursor-pointer" dimensions={{ width: 90, height: 90 }} />
                          <FullFolder className="desktop:hidden cursor-pointer"
                                      dimensions={{ width: 34, height: 34 }} />
                        </>
                      ) : (
                        <>
                          <EmptyFolder className="mobile:hidden cursor-pointer"
                                       dimensions={{ width: 90, height: 90 }} />
                          <EmptyFolder className="desktop:hidden cursor-pointer"
                                       dimensions={{ width: 34, height: 34 }} />
                        </>
                      )}
                      <div className="text-center">
                        <p
                          className="text-bodySR text-dark3 desktop:ml-5 desktop:text-center">{removeSlash(folderName)}</p>
                      </div>
                    </div>
                  </div>
                  <div className={`flex items-center relative`}>
                    <input
                      type="checkbox"
                      id={`folder-checkbox-${folderName}`}
                      className={`rounded-lg  right-[4px] top-[3px] ml-auto opacity-0 absolute z-10 ${
                        selectedFolders.includes(folderName) ? "opacity-100" : ""
                      } desktop:hidden group-hover:opacity-100`}
                      checked={selectedFolders.includes(folderName)}
                      onChange={(e) => handleCheckboxChange(folderName, e.target.checked)}
                    />
                    <div
                      className={`desktop:hidden relative z-20 cursor-pointer ${selectedFolders.includes(folderName) ? "hidden" : ""}`}
                      onClick={() => handleCheckboxChange(folderName, !selectedFolders.includes(folderName))}
                    >
                      <DotsIcon />
                    </div>
                  </div>

                </div>
              )
            )
          ) : (
            <p className={`text-dark3 mobile:text-[12px]`}>No folders found</p>
          )}
        </div>
      </div>
      <div className="files space-y-4">
        <h6 className="text-bodyLR text-dark4">Files</h6>
        <div
          className="content-files flex mobile:flex-col desktop:flex-row desktop:space-x-6 mobile:space-y-4 desktop:items-center">
          {files.length > 2 ? (
            files.map(
              ([fileName, fileInfo]) => (
                <div key={fileName}
                     className={`folder-item ml-0 flex flex-row mobile:justify-between mobile:items-center`}>
                  {["doc", "docx", "txt", "pdf", "jpg", "jpeg", "png"].includes(getExtension(fileName)) ? (
                    <label
                      className={`flex flex-row relative desktop:flex-col items-center desktop:border  ${
                        selectedFolders.includes(fileName) ? "desktop:bg-blueLight5" : "desktop:bg-white"
                      } desktop:rounded-lg desktop:p-4 desktop:hover:shadow-shadow5  group`}
                      htmlFor={`folder-checkbox-${fileName}`}
                    >
                      <input
                        type="checkbox"
                        id={`folder-checkbox-${fileName}`}
                        className={`rounded-lg absolute right-2 top-2  opacity-0 ${
                          selectedFolders.includes(fileName) ? "opacity-100" : ""
                        } mobile:hidden group-hover:opacity-100`}
                        checked={selectedFolders.includes(fileName)}
                        onChange={(e) => handleCheckboxChange(fileName, e.target.checked)}
                      />
                      <div className={`mobile:flex mobile:items-center`}>
                        <div className="cursor-pointer">
                          {["doc", "docx", "txt", "pdf"].includes(getExtension(fileName)) ? (
                            <>
                              <DocumentIcon className="mobile:hidden" dimensions={{ width: 106, height: 90 }} />
                              <DocumentIcon className="desktop:hidden" dimensions={{ width: 34, height: 34 }} />
                            </>
                          ) : null}

                          {["jpg", "jpeg", "png"].includes(getExtension(fileName)) ? (
                            <>
                              <ImageIcon className="mobile:hidden" dimensions={{ width: 106, height: 90 }} />
                              <ImageIcon className="desktop:hidden" dimensions={{ width: 34, height: 34 }} />
                            </>
                          ) : null}

                          {["mp4"].includes(getExtension(fileName)) ? (
                            <>
                              <MovieIcon className="mobile:hidden" dimensions={{ width: 106, height: 90 }} />
                              <MovieIcon className="desktop:hidden" dimensions={{ width: 34, height: 34 }} />
                            </>
                          ) : null}

                          {["zip"].includes(getExtension(fileName)) ? (
                            <>
                              <ZipDir className="mobile:hidden" dimensions={{ width: 106, height: 90 }} />
                              <ZipDir className="desktop::hidden" dimensions={{ width: 34, height: 34 }} />
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div>
                        <p className="text-bodySR text-dark3 ml-2 desktop:text-center">{fileName}</p>
                      </div>
                    </label>
                  ) : null}
                  <div className={`flex items-center relative`}>
                    <input
                      type="checkbox"
                      id={`folder-checkbox-${fileName}`}
                      className={`rounded-lg right-0 top-0 ml-auto absolute z-10 opacity-0 ${
                        selectedFolders.includes(fileName) ? "opacity-100" : ""
                      } desktop:hidden group-hover:opacity-100`}
                      checked={selectedFolders.includes(fileName)}
                      onChange={(e) => handleCheckboxChange(fileName, e.target.checked)}
                    />
                    <div
                      className={`desktop:hidden relative z-20 cursor-pointer ${selectedFolders.includes(fileName) ? "hidden" : ""}`}
                      onClick={() => handleCheckboxChange(fileName, !selectedFolders.includes(fileName))}
                    >
                      <DotsIcon />
                    </div>
                  </div>

                </div>
              )
            )
          ) : (

            <p className={`text-dark3 mobile:text-[12px]`}>Files not exist</p>
          )}
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default ContentFiles;
