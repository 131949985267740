import { useEffect, useState } from "react";
import axiosConfig from "../axiosConfig";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const UseSelectedFoldersOrFiles = () => {
  const navigate = useNavigate();
  const [folderStructure, setFolderStructure] = useState({});
  const [selectedFolders, setSelectedFolders] = useState([]);

  useEffect(() => {
    axiosConfig
      .get("/file-manager/get-folder-structure")
      .then((response) => {
        const { directoryStructure } = response.data;
        const sanitizedStructure = sanitizeDirectoryNames(directoryStructure);
        setFolderStructure(sanitizedStructure);
      })
      .catch((error) => {
        toast.error("Error fetching data", error.data);
      });
  }, []);
  const sanitizeDirectoryNames = (structure) => {
    const sanitizedStructure = {};
    for (const key in structure) {
      const sanitizedKey = key.replace(/^\//, "");
      const isDirectory = structure[key].isDirectory;
      const hasFiles = Object.keys(structure[key]).some(
        (item) => item !== "isDirectory",
      );
      sanitizedStructure[sanitizedKey] = {
        ...structure[key],
        hasFiles: isDirectory && hasFiles,
      };
    }
    return sanitizedStructure;
  };

  const getExtension = (fileName) => {
    const parts = fileName.split(".");
    if (parts.length >= 2) {
      const extension = parts[parts.length - 1];
      return extension.toLowerCase();
    } else {
      return "";
    }
  };
  const handleCheckboxChange = (folderName, isChecked) => {
    if (isChecked) {
      setSelectedFolders([...selectedFolders, folderName]);
    } else {
      setSelectedFolders(selectedFolders.filter((name) => name !== folderName));
    }
  };

  const updateFolderStructure = (newStructure) => {
    setFolderStructure(newStructure)
  }

  return {
    folderStructure,
    selectedFolders,
    handleCheckboxChange,
    getExtension,
    updateFolderStructure
  };
};

export default UseSelectedFoldersOrFiles;
