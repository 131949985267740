import React from "react";

const DocumentIcon = ({ dimensions, className }) => {
  const { width, height } = dimensions;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 106 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.714 71.7054H76.2765V75.0923H29.714V71.7054ZM29.714 57.5107H76.2765V60.8975H29.714V57.5107ZM29.714 43.3066H76.2765V46.6934H29.714V43.3066ZM29.714 29.1025H76.2765V32.4893H29.714V29.1025ZM51.274 14.8984H76.286V18.2852H51.274V14.8984ZM46.1151 0V14.3542C46.1151 16.7653 45.1154 18.9701 43.5215 20.5556L43.512 20.565C41.9087 22.1599 39.7018 23.1544 37.2873 23.1544H19V84.5867C19 86.069 19.613 87.4294 20.6033 88.4051C21.5936 89.3808 22.9517 90 24.4419 90H81.5581C83.0577 90 84.4158 89.3902 85.3966 88.4051H85.4061C86.3869 87.4294 87 86.0784 87 84.5867V5.42271C87 3.931 86.3869 2.58002 85.3966 1.6043L85.4061 1.59491C84.4158 0.619201 83.0577 0.009391 81.5581 0.009391H46.1151V0ZM42.7198 14.3542V1.98896L21.886 19.7675H37.2873C38.7775 19.7675 40.145 19.1577 41.1165 18.182L41.1259 18.1726C42.1068 17.1969 42.7198 15.8459 42.7198 14.3542Z"
        fill="#CED4DA"
      />
    </svg>
  );
};

export default DocumentIcon;
