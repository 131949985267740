import React from "react";

const EmptyFolder = ({ dimensions, className }) => {
  const { width, height } = dimensions;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 10.4113V3.51322C0 1.57213 1.44976 0 3.23978 0H10.2001C10.8843 0 11.5537 0.236621 12.1085 0.673769L15.1375 3.06805H32.7602C34.5502 3.06805 36 4.64018 36 6.58127V10.4073H0V10.4113Z"
        fill="#FBB03B"
      />
      <path
        d="M32.7602 6.62939H3.23978C1.44976 6.62939 0 8.20152 0 10.1426V28.4868C0 30.4279 1.44976 32 3.23978 32H32.7602C34.5502 32 36 30.4279 36 28.4868V10.1426C36 8.20152 34.5502 6.62939 32.7602 6.62939Z"
        fill="#FFCC00"
      />
    </svg>
  );
};

export default EmptyFolder;
