import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Input from "../components/form/Input";
import ButtonPrimary from "../components/buttons/ButtonPrimary";
import { signupFields } from "../constants/formFields";
import Cookies from "js-cookie";
import axiosConfig from "../axiosConfig";
import ButtonSecondary from "../components/buttons/ButtonSecondary";

const RegisterPage = () => {
  const [signupState, setSignupState] = useState(signupFields);
  const navigate = useNavigate();

  const handleChange = (e, index) => {
    setSignupState((prevState) => {
      const updatedState = [...prevState];
      updatedState[index]["value"] = e.target.value;
      return updatedState;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (signupState[2].value !== signupState[3].value) {
      toast.error("Passwords do not match");
      return;
    }
    try {
      const response = await axiosConfig.post("/register", {
        username: signupState[0].value,
        email: signupState[1].value,
        password: signupState[2].value,
      });

      // Redirect to login page after successful registration
      navigate("/login");
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const errors = Object.values(error.response.data.errors);
        errors.forEach((error) => {
          toast.error(error[0]);
        });
      } else {
        toast.error(error.response.data.error);
        console.error("Eroare:", error);
      }
    }
  };
  useEffect(() => {
    const accessToken = Cookies.get("AccessToken");
    if (!accessToken) {
      navigate("/register");
    } else {
      navigate("/dashboard");
    }
  }, []);
  return (
    <div className="card-login bg-white rounded-lg mobXS:mx-6 tabMD:mx-6 my-10 border desktop:w-1/4 desktop:mx-auto">
      <h6 className="text-h6 text-dark4 text-start py-12 px-8">
        Create your account
      </h6>
      <form className="space-y-6" onSubmit={handleSubmit}>
        <div className="space-y-px desktop:w-full flex justify-center">
          <div className="mobXS:w-full tabMD:w-1/2 desktop:w-full tabMD:items-center">
            {signupState.map((field, index) => (
              <Input
                key={field.id}
                handleChange={(e) => handleChange(e, index)}
                value={field.value}
                labelText={field.labelText}
                labelFor={field.labelFor}
                id={field.id}
                name={field.name}
                type={field.type}
                isRequired={field.isRequired}
                placeholder={
                  typeof field.placeholder === "string"
                    ? field.placeholder
                    : undefined
                }
                customClass="rounded-lg"
              />
            ))}
            <div className="flex items-center mx-6">
              <input
                type="checkbox"
                className="rounded border-stroke text-primary"
                required
              />
              <a
                className="text-dark1 text-bodyMM ml-3 cursor-pointer"
                href="ui/terms-conditions"
              >
                I agree with Terms & Conditions
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-6 px-6 mobXS:items-start mobXS:justify-self-start tabMD:items-center tabMD:justify-self-center">
          <ButtonSecondary
            text="Sign in"
            onClick=""
            bgColor="bg-secondary"
            color="text-white"
            customClass={`rounded-[25px]`}
            padding="mobXS:py-[10px] mobXS:px-[24px] tabMD:py-[10px] tabMD:px-[24px]"
            fontsize="text-bodyMR"
            width="mobXS:w-full tabMD:w-1/2 desktop:w-full"
          />
        </div>
        <div className="flex justify-center pb-10">
          <a className="text-primary text-bodyMM" href="ui/login">
            Already have an account?
          </a>
        </div>
      </form>
      <ToastContainer
        position="top-center"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default RegisterPage;
