import React from "react";

const EditMessageIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.862 3.48725L16.549 1.79925C16.9007 1.44757 17.3777 1.25 17.875 1.25C18.3723 1.25 18.8493 1.44757 19.201 1.79925C19.5527 2.15092 19.7502 2.6279 19.7502 3.12525C19.7502 3.62259 19.5527 4.09957 19.201 4.45125L8.582 15.0702C8.05332 15.5986 7.40137 15.987 6.685 16.2002L4 17.0002L4.8 14.3152C5.01328 13.5989 5.40163 12.9469 5.93 12.4182L14.862 3.48725ZM14.862 3.48725L17.5 6.12525M16 13.0002V17.7502C16 18.347 15.7629 18.9193 15.341 19.3412C14.919 19.7632 14.3467 20.0002 13.75 20.0002H3.25C2.65326 20.0002 2.08097 19.7632 1.65901 19.3412C1.23705 18.9193 1 18.347 1 17.7502V7.25025C1 6.65351 1.23705 6.08121 1.65901 5.65926C2.08097 5.2373 2.65326 5.00025 3.25 5.00025H8"
        stroke="#FF5400"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  );
};

export default EditMessageIcon;
