import React, {useState} from "react";
import {useParams, useNavigate } from "react-router-dom";
import ButtonAddNew from "../../buttons/ButtonAddNew";
import SortBy from "./SortBy";
import Search from "../header/Search";
import ModalCreateFolder from "../../modal/modalCreateFolder";
import ButtonDelete from "../../buttons/ButtonDelete";
import ButtonDownload from "../../buttons/ButtonDownload";
import ItemsSelected from "../../buttons/ItemsSelected";
import ModalUploadFile from "../../modal/modalUploadFile";
import axiosConfig from "../../../axiosConfig";
import {toast} from "react-toastify";

const NavigationMyFiles = ({selectedFolders, folderStructure}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const params = useParams();
    const navigate = useNavigate();

    console.log(selectedFolders, 'selectedFolders');
    const handleOpenModal = () => {
        setIsModalOpen(true);
        setIsVisible(false);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleCloseConfirmModal = () => {
        setIsConfirmOpen(false);
    };

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };
    console.log();
    const handleDeleteButtonClick = () => {
        if (
            selectedFolders &&
            Array.isArray(selectedFolders) &&
            selectedFolders.length > 0
        ) {
            axiosConfig
                .delete("file-manager/delete", {data: {files: [params["*"] + '/' + selectedFolders]}})
                .then((response) => {
                    setIsConfirmOpen(false);
                    toast.success("Files deleted successfully");
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                })
                .catch((error) => {
                    toast.error("Error deleting files");
                });
        } else {
            toast.error("No files selected");
        }
    };

    const handleDownloadButtonClick = () => {
        if (
            selectedFolders &&
            Array.isArray(selectedFolders) &&
            selectedFolders.length > 0
        ) {
            selectedFolders.forEach((fileName) => {
                axiosConfig
                    .get(`/file-manager/download/?filepath=/${fileName}`, {
                        responseType: "blob",
                    })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode.removeChild(link);
                        toast.success(`${fileName} downloaded successfully`);
                    })
                    .catch((error) => {
                        toast.error(`Error downloading ${fileName}`);
                    });
            });
        } else {
            toast.error("No files selected");
        }
    };

    const handleUploadModalOpen = () => {
        setIsUploadModalOpen(true);
    };

    const handleUploadModalClose = () => {
        setIsUploadModalOpen(false);
    };

    const formattedFilesRoute = params["*"].split('/')

    const navigateToRoute = (index) => {
        const route = formattedFilesRoute.slice(0, index + 1).join("/")
        navigate(route)
    }
    return (
        <div>
            <div className="title-and-button mobile:bg-white space-y-6 py-6">
                <div className="flex flex-row justify-between px-6 items-center">
                    <div className={`flex basis-1/2`}>
                        <h6 className="text-dark4 text-h6 cursor-pointer">
                            <span onClick={() => navigate("/dashboard/my-files")} className={`mobile:text-[12px]`}>My Files {formattedFilesRoute[0] ? " > " : ""}</span>
                            {formattedFilesRoute.map((route, index) => (
                              <span className={`mobile:text-[12px]`} key={index} onClick={() => navigateToRoute(index)}>{Boolean(index > 0) && " > "}{route}</span>
                            ))}
                        </h6>
                    </div>
                    {selectedFolders?.length > 0 && <div
                        className={`buttons-action flex flex-row space-x-12 mobile:hidden`}
                    >
                        <ButtonDownload
                            className="border-0"
                            downloadAction={handleDownloadButtonClick}
                        />
                        <ButtonDelete
                            className="border-0"
                            deleteAction={handleDeleteButtonClick}
                        />
                        <ItemsSelected
                            className="rounded-xl"
                            selectedFoldersLength={
                                selectedFolders ? selectedFolders?.length : 0
                            }
                        />
                    </div>}
                    <div className="flex flex-row space-x-6">
                        <SortBy textSort="SortBy" className="desktop:hidden mobile:hidden"/>
                        <div className="relative cursor-pointer">
                            <ButtonAddNew textButton="Add new" onClick={toggleVisibility} className={`mobile:px-[8px] mobile:py-[8px]`} />
                            {isVisible && (
                                <div
                                    className="bg-white border cursor-pointer absolute w-[200px] right-[0px] rounded-lg mt-2 z-20">
                                    <p
                                        className="text-bodyMR text-dark4 hover:text-white hover:bg-primary px-2 py-2"
                                        onClick={handleOpenModal}
                                    >
                                        New folder
                                    </p>
                                    <p
                                        className="text-bodyMR text-dark4 hover:text-white hover:bg-primary px-2 py-2"
                                        onClick={handleUploadModalOpen}
                                    >
                                        New file
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-between px-6 items-center  desktop:hidden">
                    <div className={`mobile:w-full`}>
                        <Search/>
                    </div>
                    <div>
                        <SortBy textSort="SortBy" className="desktop:hidden mobile:hidden"/>
                    </div>
                </div>
                <div
                    className={`buttons-action flex flex-row px-3 items-center justify-center space-x-6 ${selectedFolders?.length > 0 ? "" : "hidden"} desktop:hidden`}
                >
                    <ButtonDownload className="border-0"/>
                    <ButtonDelete
                        className="border-0"
                        deleteAction={handleDeleteButtonClick}
                    />
                    <ItemsSelected
                        className="rounded-xl mobile:px-[4px] mobile:py-[4px]"
                        selectedFoldersLength={selectedFolders ? selectedFolders?.length : 0}
                    />
                </div>
            </div>
            <ModalCreateFolder isOpen={isModalOpen} onClose={handleCloseModal} selectedFolder={selectedFolders}/>
            <ModalUploadFile
                selectedFolder={selectedFolders}
                isOpen={isUploadModalOpen}
                onClose={handleUploadModalClose}
            />
        </div>
    );
};

export default NavigationMyFiles;
