import React, { useState } from "react";
import SortByIcon from "../../../icons/SortByIcon";

function SortBy({ textSort, className, items }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleList = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (e) => {
    console.log("Opțiune selectată:", e.target.textContent);
    setIsOpen(false);
  };

  return (
    <div
      className={`sort flex flex-row  items-center space-x-2 relative ${className}`}
      onClick={toggleList}
    >
      <SortByIcon />
      <p className="text-dark4 text-bodyMR cursor-pointer">{textSort}</p>
      <ul
        className={`bg-white shadow-shadow5 rounded-lg w-[195px] h-[125px] absolute top-[40px] right-0 ${isOpen ? "" : "hidden"}`}
      >
        {items &&
          items.map((item, index) => (
            <li
              key={index}
              className="text-dark4 hover:bg-primary hover:text-white px-[16px] py-[7px]"
              onClick={handleChange}
            >
              {item}
            </li>
          ))}
      </ul>
    </div>
  );
}

export default SortBy;
