import React, {useEffect, useState} from "react";
import axiosConfig from "../axiosConfig";
import {toast, ToastContainer} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import ButtonApprove from "../components/buttons/ButtonApprove";
import ButtonAttach from "../components/buttons/ButtonAttach";
import ModalDeleteMessage from "../components/modal/modalDeleteMessage";
import ModalAttachFiles from "../components/modal/modalAttachFiles";
import DocumentIcon from "../icons/DocumentIcon";
import ImageIcon from "../icons/ImageIcon";
import MovieIcon from "../icons/MovieIcon";
import ZipDir from "../icons/ZipDir";
import {Hourglass} from "react-loader-spinner";

const EditMessage = () => {
  const { messageId } = useParams();
  const navigate = useNavigate();
  const [filePaths, setFilePaths] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAttachOpen, setIsAttachOpen] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);

  const escapeHTML = (body) => {
    return body.replace(/<[^>]+>/g, "");
  };
  const handleDiscardChangesClick = () => {
    setIsModalOpen(true);
  };
  const handleCloseAttachModal = () => {
    setIsAttachOpen(false);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleAttachButtonClick = () => {
    setIsAttachOpen(true);
  };
  const handleFolderSelect = (selectedFolderPaths) => {
    setFilePaths(selectedFolderPaths.join(","));
  };
  const [message, setMessage] = useState({
    id: null,
    label: "",
    subject: "",
    recipient_email: "",
    body: "",
    file_paths: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMessage((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleApproveChangesClick = () => {
    let formattedFilePaths = '';
    if (message.file_paths && message.file_paths.length > 0) {
      const existingFileNames = message.file_paths.map(file => file.path);
      formattedFilePaths = existingFileNames.join(',') + ',' + filePaths;
    } else {
      formattedFilePaths = filePaths;
    }
    const formattedData = {
      label: message.label,
      subject: message.subject,
      recipient_email: message.recipient_email,
      body: message.body,
      file_paths: formattedFilePaths + ",",
    };
    setLoading(true);
    axiosConfig
        .post(`outbox/edit/${messageId}`, formattedData)
        .then((response) => {
          toast.success("Message updated successfully");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        })
        .catch((error) => {
          toast.error("Error updating message", error);
        })
        .finally(() => {
          setLoading(false);
        });
  };


  useEffect(() => {
    setLoading(true);
    if (messageId) {
      axiosConfig
        .get(`outbox/edit/get-details/${messageId}`)
        .then((response) => {
          const fetchedMessage = response.data.message;
          setMessage(fetchedMessage);

          if (
            fetchedMessage.file_paths &&
            fetchedMessage.file_paths.length > 0
          ) {
            const attachmentNames = fetchedMessage.file_paths.map((file) => {
              const pathParts = file.path.split("/");
              return pathParts[pathParts.length - 1];
            });
            setAttachments((prevAttachments) => [
              ...prevAttachments,
              ...attachmentNames,
            ]);
          }
        })
        .catch((error) => {
          toast.error("Error fetching data", error.data);
        })
        .finally(() => {
          setLoading(false);
          // setFirstLoad(false);
        });
    }
  }, [messageId]);

  const getExtension = (fileName) => {
    const parts = fileName.split(".");
    if (parts.length >= 2) {
      const extension = parts[parts.length - 1];
      return extension.toLowerCase();
    } else {
      return "";
    }
  };
  const filteredAttachments = attachments.filter(attachment => attachment !== '');
  return (
    <div>
      {loading ? (
        <div className="flex flex-row justify-center items-center mt-10">
          <Hourglass
            visible={true}
            height="40"
            width="40"
            animationDuration="2"
            ariaLabel="hourglass-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={["#306cce", "#72a1ed"]}
          />
        </div>
      ) : (
        <>
          <div className="desktop:hidden flex flex-row justify-between bg-white p-6 cursor-pointer">
            <a
              className="flex flex-row items-center space-x-2"
              href="/ui/dashboard"
            >
              <img src="/ui/back.svg" alt="back" />
              <p className="text-primary text-bodyMM">Back</p>
            </a>
            <ButtonApprove
              className="desktop:hidden"
              onClick={handleApproveChangesClick}
            />
          </div>
          <div className="desktop:w-[75%] desktop:h-[100%] left-0 right-0 mx-auto bg-white  mobile:bg-gray2 rounded-2xl">
            <div className={`message-new flex-col h-full p-9`}>
              <div className="header space-y-6">
                <div className="header-sb-mail hidden flex-row justify-between">
                  <p className="text-bodyLM text-black"></p>
                  <p className="text-bodyMM text-dark5">Cc Bcc</p>
                </div>
                <div className="header-to-mail border-b-2 flex flex-row  items-center space-x-2">
                  <label className="text-bodySR text-black">Name:</label>
                  <input
                    type="text"
                    id="label"
                    name="label"
                    className="w-full mobile:bg-gray2 text-bodySR text-dark5 border-0 focus:ring-transparent focus:ring-offset-0 focus:ring-0 focus:shadow-none"
                    value={message.label}
                    onChange={handleChange}
                  />
                </div>
                <div className="header-to-mail border-b-2 flex flex-row  items-center space-x-2">
                  <label className="text-bodySR text-black">To:</label>
                  <input
                    type="email"
                    id="recipient_email"
                    name="recipient_email"
                    className="email-address  mobile:bg-gray2 w-full text-bodySR text-dark5 border-0 focus:ring-transparent focus:ring-offset-0 focus:ring-0 focus:shadow-none"
                    value={message.recipient_email}
                    onChange={handleChange}
                  />
                </div>
                <div className="header-to-mail border-b-2 flex flex-row items-center space-x-2">
                  <label className="text-bodySR text-black">Subject:</label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    className="subject mobile:bg-gray2  w-full text-bodySR text-dark5 border-0 focus:ring-transparent focus:ring-offset-0 focus:ring-0 focus:shadow-none"
                    value={message.subject}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex w-full mt-5">
                <textarea
                  id="body"
                  name="body"
                  style={{ resize: "none" }}
                  className="body w-full mobile:bg-gray2 desktop:h-[300px] mobile:h-auto border-2 rounded-lg border-gray2 focus:ring-transparent focus:ring-offset-0 focus:ring-0 focus:border-gray2"
                  value={escapeHTML(message.body)}
                  onChange={handleChange}
                ></textarea>
              </div>
              {filteredAttachments.length > 0 && (
                <div className={`attachments ${filteredAttachments.length === 0 ? 'desktop:bg-white' : 'desktop:bg-gray2'} mt-10 mobile:bg-white desktop:bg-gray2 rounded-lg  py-5 flex flex-wrap`}>
                  {filteredAttachments.map((attachment, index) => (
                    <div className="element-at cursor-pointer pl-5" key={index} style={{ flex: '0 0 calc(50% - 8px)' }}>
                      {["doc", "docx", "txt", "pdf"].includes(
                          getExtension(attachment),
                        ) ? (
                          <div className="flex flex-row items-center">
                            <DocumentIcon
                              className="mobile:hidden"
                              dimensions={{ width: 50, height: 50 }}
                            />
                            <DocumentIcon
                              className="desktop:hidden"
                              dimensions={{ width: 34, height: 34 }}
                            />
                            <p className="text-bodySR text-dark3">
                              {attachment}
                            </p>
                          </div>
                        ) : null}

                        {["jpg", "jpeg", "png"].includes(getExtension(attachment)) ? (
                          <div className="flex flex-row items-center">
                            <ImageIcon
                              className="mobile:hidden"
                              dimensions={{ width: 50, height: 50 }}
                            />
                            <ImageIcon
                              className="desktop:hidden"
                              dimensions={{ width: 34, height: 34 }}
                            />
                            <p className="text-bodySR text-dark3">
                              {attachment}
                            </p>
                          </div>
                        ) : null}

                        {["mp4"].includes(getExtension(attachment)) ? (
                          <div className="flex flex-row items-center">
                            <MovieIcon
                              className="mobile:hidden"
                              dimensions={{ width: 50, height: 50 }}
                            />
                            <MovieIcon
                              className="desktop:hidden"
                              dimensions={{ width: 34, height: 34 }}
                            />
                            <p className="text-bodySR text-dark3">
                              {attachment}
                            </p>
                          </div>
                        ) : null}

                        {["zip"].includes(getExtension(attachment)) ? (
                          <div className="flex flex-row items-center">
                            <ZipDir
                              className="mobile:hidden"
                              dimensions={{ width: 50, height: 50 }}
                            />
                            <ZipDir
                              className="desktop::hidden"
                              dimensions={{ width: 34, height: 34 }}
                            />
                            <p className="text-bodySR text-dark3">
                              {attachment}
                            </p>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
              )}

              <div className="footer flex flex-row justify-between  mt-10">
                <div className="flex flex-row gap-5">
                  <ButtonApprove
                    className="mobile:hidden"
                    onClick={handleApproveChangesClick}
                  />
                  <ButtonAttach onClick={handleAttachButtonClick} />
                </div>
                <div className="flex items-center">
                  <button
                    className="text-dark4 text-bodyMM"
                    onClick={handleDiscardChangesClick}
                  >
                    Delete message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isModalOpen && (
        <ModalDeleteMessage
          titleModal="Are you sure you want to delete this message?"
          textModal="This action is ireversible."
          closeModal={closeModal}
          handleDiscardChangesClick={handleDiscardChangesClick}
        />
      )}
      {isAttachOpen && (
        <ModalAttachFiles
          titleModal="Attach a file"
          onSelectFolders={handleFolderSelect}
          closeModal={handleCloseAttachModal}
        />
      )}
      <ToastContainer
        position="top-center"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default EditMessage;
