import React from "react";
import ButtonSecondary from "../buttons/ButtonSecondary";

const ModalConfirm = ({
  isOpen,
  closeModal,
  titleModal,
  textModal,
  textButton,
  classButton,
}) => {
  const handleCancel = () => {
    closeModal();
  };
  const handleUpdate = () => {
    closeModal();
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-lg mx-6">
        <h2 className="text-h6 text-dark4 mb-4 text-center">{titleModal}</h2>
        <p className="text-bodyLR text-dark4 text-center">{textModal}</p>
        <div className="flex-row flex gap-2 mobile:pt-8 desktop:pt-12 16cursor-pointer justify-center">
          <ButtonSecondary
            text="Discard"
            customClass="bg-redDark text-white text-center justify-center"
            width="mobile:w-full desktop:w-1/4"
            padding="px-[5px] py-[10px]"
            onClick={handleUpdate}
          />
          <ButtonSecondary
            text="Cancel"
            customClass="bg-white border text-dark4 text-center justify-center"
            width="mobile:w-full desktop:w-1/4"
            padding="px-[5px] py-[10px]"
            onClick={handleCancel}
          />
        </div>
      </div>
    </div>
  );
};

export default ModalConfirm;
