import React, { useEffect, useState } from "react";
import ButtonSecondary from "../buttons/ButtonSecondary";
import EmptyFolder from "../../icons/EmptyFolder";
import DotsIcon from "../../icons/DotsIcon";
import DocumentIcon from "../../icons/DocumentIcon";
import ImageIcon from "../../icons/ImageIcon";
import MovieIcon from "../../icons/MovieIcon";
import ZipDir from "../../icons/ZipDir";
import { toast } from "react-toastify";
import axiosConfig from "../../axiosConfig";
import ItemsSelected from "../buttons/ItemsSelected";
import { Hourglass } from "react-loader-spinner";
import FullFolder from "../../icons/FullFolder";

const ModalAttachFiles = ({ closeModal, titleModal, onSelectFolders }) => {
  const [folderStructure, setFolderStructure] = useState({});
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openFolders, setOpenFolders] = useState({});
  const [navigationPath, setNavigationPath] = useState([]);

  const handleCancel = () => {
    closeModal();
  };

  const handleUpdate = () => {
    onSelectFolders(selectedFolders);
    closeModal();
  };

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get("/file-manager/get-folder-structure")
      .then((response) => {
        const { directoryStructure } = response.data;
        const sanitizedStructure = sanitizeDirectoryNames(directoryStructure);
        setFolderStructure(sanitizedStructure);
      })
      .catch((error) => {
        toast.error("Error fetching data", error.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const sanitizeDirectoryNames = (structure) => {
    const sanitizedStructure = {};
    for (const key in structure) {
      sanitizedStructure[key] = structure[key];
    }
    return sanitizedStructure;
  };

  const getExtension = (fileName) => {
    const parts = fileName.split(".");
    if (parts.length >= 2) {
      const extension = parts[parts.length - 1];
      return extension.toLowerCase();
    } else {
      return "";
    }
  };

  const handleCheckboxChange = (folderName, isChecked) => {
    const filePath = `${navigationPath.join('/')}${folderName}`;
    if (isChecked) {
      setSelectedFolders([...selectedFolders, filePath]);
    } else {
      setSelectedFolders(selectedFolders.filter((name) => name !== filePath));
    }
  };

  const navigateToFolder = (folderName) => {
    setNavigationPath([...navigationPath, folderName]);
  };

  const navigateBack = () => {
    setNavigationPath(navigationPath.slice(0, -1));
  };

  const getCurrentStructure = () => {
    let currentStructure = folderStructure;
    for (const folder of navigationPath) {
      currentStructure = currentStructure[folder];
    }
    if (typeof currentStructure === 'object' && currentStructure !== null && 'isDirectory' in currentStructure) {
      const { isDirectory, ...newStructure } = currentStructure;
      return newStructure;
    }

    return currentStructure;
  };


  const renderFolderContents = (structure, path = "") => {
    return Object.entries(structure).map(([name, info]) => {
      const currentPath = path ? `${path}/${name}` : name;
      const hasFiles = Object.keys(info).filter(key => key !== 'isDirectory').length > 0;
      console.log(selectedFolders.includes(currentPath));
      if (info.isDirectory) {
        return (
          <div key={currentPath}>
            <div className={`folder-item flex flex-row mobile:justify-between mobile:items-center`}>
              <div className={`flex flex-row relative desktop:flex-col items-center desktop:border ${
                selectedFolders.includes(currentPath)
                  ? "desktop:bg-blueLight5"
                  : "desktop:bg-white"
              } desktop:rounded-lg desktop:p-4 desktop:hover:shadow-shadow5 group`}>
              <label htmlFor={`folder-checkbox-${currentPath}`}>
                <input
                  type="checkbox"
                  id={`folder-checkbox-${currentPath}`}
                  className={`rounded-lg absolute right-2 top-2 opacity-0 ${
                    selectedFolders.includes(currentPath)
                      ? "opacity-100"
                      : ""
                  } mobile:hidden group-hover:opacity-100`}
                  checked={selectedFolders.includes(currentPath)}
                  onChange={(e) =>
                    handleCheckboxChange(currentPath, e.target.checked)
                  }
                />
              </label>
                <div
                  className="cursor-pointer"
                  onClick={() => navigateToFolder(name)}
                >
                  {hasFiles ? (
                    <>
                      <FullFolder
                        className="mobile:hidden cursor-pointer"
                        dimensions={{ width: 90, height: 90 }}
                      />
                      <FullFolder
                        className="desktop:hidden cursor-pointer"
                        dimensions={{ width: 34, height: 34 }}
                      />
                    </>
                  ) : (
                    <>
                      <EmptyFolder
                        className="mobile:hidden cursor-pointer"
                        dimensions={{ width: 90, height: 90 }}
                      />
                      <EmptyFolder
                        className="desktop:hidden cursor-pointer"
                        dimensions={{ width: 34, height: 34 }}
                      />
                    </>
                  )}
                </div>
                <div>
                  <p className="text-bodySR text-dark3 ml-5 desktop:text-center">
                    {name}
                  </p>
                </div>
              </div>
              <input
                type="checkbox"
                id={`folder-checkbox-${currentPath}`}
                className={`rounded-lg right-0 top-0 ml-auto relative opacity-0 ${
                  selectedFolders.includes(currentPath) ? "opacity-100" : ""
                } desktop:hidden group-hover:opacity-100`}
                checked={selectedFolders.includes(currentPath)}
                onChange={(e) =>
                  handleCheckboxChange(currentPath, e.target.checked)
                }
              />
              <div
                className={`desktop:hidden ${
                  selectedFolders.includes(currentPath) ? "hidden" : ""
                }`}
                onClick={() =>
                  handleCheckboxChange(
                    currentPath,
                    !selectedFolders.includes(currentPath)
                  )
                }
              >
                <DotsIcon />
              </div>
            </div>
            {openFolders[currentPath] &&
              renderFolderContents(info, currentPath)}
          </div>
        );
      } else {
        return (
          <div
            key={currentPath}
            className={`folder-item flex flex-row mobile:justify-between mobile:items-center`}
          >
            <label
              className={`flex flex-row relative desktop:flex-col items-center desktop:border ${
                selectedFolders.includes(currentPath)
                  ? "desktop:bg-blueLight5"
                  : "desktop:bg-white"
              } desktop:rounded-lg desktop:p-4 desktop:hover:shadow-shadow5 group`}
              htmlFor={`folder-checkbox-${currentPath}`}
            >
              <input
                type="checkbox"
                id={`folder-checkbox-${currentPath}`}
                className={`rounded-lg absolute right-2 top-2 opacity-0 ${
                  selectedFolders.includes(currentPath) ? "opacity-100" : ""
                } mobile:hidden group-hover:opacity-100`}
                checked={selectedFolders.includes(currentPath)}
                onChange={(e) =>
                  handleCheckboxChange(currentPath, e.target.checked)
                }
              />
              <div className="cursor-pointer">
                {["doc", "docx", "txt", "pdf"].includes(
                  getExtension(name)
                ) ? (
                  <>
                    <DocumentIcon
                      className="mobile:hidden"
                      dimensions={{ width: 106, height: 90 }}
                    />
                    <DocumentIcon
                      className="desktop:hidden"
                      dimensions={{ width: 34, height: 34 }}
                    />
                  </>
                ) : null}

                {["jpg", "jpeg", "png"].includes(getExtension(name)) ? (
                  <>
                    <ImageIcon
                      className="mobile:hidden"
                      dimensions={{ width: 106, height: 90 }}
                    />
                    <ImageIcon
                      className="desktop:hidden"
                      dimensions={{ width: 34, height: 34 }}
                    />
                  </>
                ) : null}

                {["mp4"].includes(getExtension(name)) ? (
                  <>
                    <MovieIcon
                      className="mobile:hidden"
                      dimensions={{ width: 106, height: 90 }}
                    />
                    <MovieIcon
                      className="desktop:hidden"
                      dimensions={{ width: 34, height: 34 }}
                    />
                  </>
                ) : null}

                {["zip"].includes(getExtension(name)) ? (
                  <>
                    <ZipDir
                      className="mobile:hidden"
                      dimensions={{ width: 106, height: 90 }}
                    />
                    <ZipDir
                      className="desktop:hidden"
                      dimensions={{ width: 34, height: 34 }}
                    />
                  </>
                ) : null}
              </div>
              <div>
                <p className="text-bodySR text-dark3 ml-5 desktop:text-center">
                  {name}
                </p>
              </div>
            </label>
            <input
              type="checkbox"
              id={`folder-checkbox-${currentPath}`}
              className={`rounded-lg right-0 top-0 ml-auto relative opacity-0 ${
                selectedFolders.includes(currentPath) ? "opacity-100" : ""
              } desktop:hidden group-hover:opacity-100`}
              checked={selectedFolders.includes(currentPath)}
              onChange={(e) =>
                handleCheckboxChange(currentPath, e.target.checked)
              }
            />
            <div
              className={`desktop:hidden ${
                selectedFolders.includes(currentPath) ? "hidden" : ""
              }`}
              onClick={() =>
                handleCheckboxChange(
                  currentPath,
                  !selectedFolders.includes(currentPath)
                )
              }
            >
              <DotsIcon />
            </div>
          </div>
        );
      }
    });
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white mobile:w-full p-8 rounded-lg mx-6">
        <h2 className="text-h6 text-dark4 mb-4 text-start border-b-2 border-b-stroke">
          {titleModal}
        </h2>
        {loading ? (
          <div className="flex flex-row justify-center items-center mt-10">
            <Hourglass
              visible={true}
              height="40"
              width="40"
              animationDuration="2"
              ariaLabel="hourglass-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors={["#306cce", "#72a1ed"]}
            />
          </div>
        ) : (
          <>
            <div className="content-files-attachment space-y-10">
              <div className="folders-attach space-y-4">
                <div className="flex justify-between items-center">
                  <ItemsSelected
                    className={`rounded-xl ${
                      selectedFolders.length > 0 ? "" : "hidden"
                    }`}
                    selectedFoldersLength={selectedFolders.length}
                  />
                </div>
                {navigationPath.length > 0 && (
                  <div className="mb-4">
                    <button className="text-bodyLR text-dark4" onClick={navigateBack}>Back</button>
                  </div>
                )}
                <div className="content-folders flex mobile:flex-col desktop:flex-row desktop:space-x-6 mobile:space-y-4 desktop:items-center cursor-pointer">
                  {renderFolderContents(getCurrentStructure())}
                </div>
              </div>
            </div>
            <div className="flex-row flex gap-4 mobile:pt-8 desktop:pt-12 cursor-pointer border-t-2 border-t-stroke mt-10 justify-end">
              <ButtonSecondary
                text="Cancel"
                customClass="bg-white border text-dark4 text-center justify-center"
                width="mobile:w-full desktop:w-[125px]"
                padding="px-[5px] py-[10px]"
                onClick={handleCancel}
              />
              <ButtonSecondary
                text="Attach"
                customClass="bg-primary text-white text-center justify-center"
                width="mobile:w-full desktop:w-[125px]"
                padding="px-[5px] py-[10px]"
                onClick={handleUpdate}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ModalAttachFiles;
