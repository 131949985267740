import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

export default function DynamicNav({ links, hover, navigateTo }) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (e, url) => {
    e.preventDefault();
    if (navigateTo) {
      navigate(url);
    } else {
      const element = document.getElementById(url.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  const compareBaseURL = (currentPath, linkPath) => {
    const getBasePath = (path) => {
      const segments = path.split('/').filter(Boolean);
      return segments.slice(0, 3).join('/');

    };
    return getBasePath(currentPath) === getBasePath(linkPath);
  };

  return (
    <>
      {links.map((link) => {
        const isActive = compareBaseURL(location.pathname, link.url);
        return (
          <Link
            to={link.url}
            key={link.url}
            reloadDocument={true}
            onClick={(e) => handleClick(e, link.url)}
          >
            <div
              className={`flex ${hover} pb-3 element-li ${
                isActive ? "active" : ""
              }`}
            >
              {link.src && (
                <img src={link.src} alt="icon" className="px-4 pt-3" />
              )}
              <span className="element-a text-dark5 xl:text-menuMSB xxl:text-bodyLR pt-3 justify-start">
                {link.text}
              </span>
            </div>
          </Link>
        );
      })}
    </>
  );
}
