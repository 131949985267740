import React from "react";

const ZipDir = () => {
  return (
    <svg
      width="110"
      height="90"
      viewBox="0 0 110 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.102127 29.2819V9.88094C0.102127 4.42161 4.52372 0 9.98305 0H31.2113C33.298 0 35.3396 0.665497 37.0316 1.89497L46.2695 8.6289H100.017C105.476 8.6289 109.898 13.0505 109.898 18.5098V29.2706H0.102127V29.2819Z"
        fill="#FBB03B"
      />
      <path
        d="M100.017 18.645H9.98305C4.52372 18.645 0.102127 23.0666 0.102127 28.526V80.1189C0.102127 85.5782 4.52372 89.9998 9.98305 89.9998H100.017C105.476 89.9998 109.898 85.5782 109.898 80.1189V28.526C109.898 23.0666 105.476 18.645 100.017 18.645Z"
        fill="#FFCC00"
      />
      <path d="M69.3702 18.672V22.9683H76.5305V18.672H69.3702Z" fill="white" />
      <path
        d="M76.5305 22.9684V27.2646H83.6909V22.9684H76.5305Z"
        fill="white"
      />
      <path
        d="M69.3702 27.2643V31.5605H76.5305V27.2643H69.3702Z"
        fill="white"
      />
      <path
        d="M76.5305 31.5607V35.8569H83.6909V31.5607H76.5305Z"
        fill="white"
      />
      <path
        d="M69.3702 35.8571V40.1533H76.5305V35.8571H69.3702Z"
        fill="white"
      />
      <path d="M76.5305 40.153V44.4492H83.6909V40.153H76.5305Z" fill="white" />
      <path
        d="M69.3702 44.4494V48.7456H76.5305V44.4494H69.3702Z"
        fill="white"
      />
      <path
        d="M76.9377 65.6925C77.5566 65.6925 78.0566 65.2187 78.0566 64.6322C78.0566 64.4856 78.0209 64.3502 77.9732 64.2148C77.759 64.542 77.3781 64.7675 76.9377 64.7675C76.4973 64.7675 76.1164 64.5532 75.9021 64.2148C75.8426 64.3389 75.8188 64.4856 75.8188 64.6322C75.8188 65.2187 76.3188 65.6925 76.9377 65.6925Z"
        fill="white"
      />
      <path
        d="M82.1514 53.5329L78.6877 52.2696C77.5569 51.8522 76.3071 51.8522 75.1883 52.2696L71.7246 53.5329C70.5819 53.9502 69.9034 55.0556 70.0939 56.2061L70.939 61.4286C71.1056 62.455 71.6055 63.4025 72.3554 64.147C72.7482 64.5418 73.0339 65.0155 73.1767 65.5344L73.2838 64.4741C73.4147 63.1882 74.2836 62.0828 75.5453 61.5865L75.7239 61.5188V56.3077C75.7239 55.6647 76.2714 55.1571 76.938 55.1571C77.6046 55.1571 78.1521 55.676 78.1521 56.3077V61.5076L78.3306 61.5752C79.5923 62.0715 80.4612 63.1657 80.5922 64.4628L80.6993 65.5231C80.8421 65.0042 81.1159 64.5305 81.5206 64.1357C82.2824 63.3912 82.7704 62.4438 82.937 61.4173L83.7821 56.1949C83.9726 55.0556 83.2941 53.9502 82.1514 53.5329Z"
        fill="white"
      />
      <path
        d="M80.8068 78.2214L79.4877 64.1896C79.3777 63.026 78.6522 62.0564 77.6518 61.6343V63.2884C77.6518 63.3112 77.6518 63.3341 77.6518 63.3569C77.8607 63.6193 77.9926 63.9615 77.9926 64.3265C77.9926 65.1593 77.344 65.8438 76.5306 65.8438C75.7171 65.8438 75.0685 65.1707 75.0685 64.3265C75.0685 63.9615 75.2004 63.6193 75.4093 63.3569C75.4093 63.3341 75.4093 63.3112 75.4093 63.2884V61.6343C74.4199 62.0564 73.6834 63.0146 73.5735 64.1896L72.2543 78.2214C72.0125 80.8452 73.9912 83.1154 76.5306 83.1154C79.0589 83.1154 81.0487 80.8452 80.8068 78.2214ZM76.5196 81.3472C75.1015 81.3472 73.9472 80.1493 73.9472 78.6777C73.9472 77.2061 75.1015 76.0083 76.5196 76.0083C77.9377 76.0083 79.0919 77.2061 79.0919 78.6777C79.0919 80.1493 77.9377 81.3472 76.5196 81.3472Z"
        fill="white"
      />
    </svg>
  );
};

export default ZipDir;
