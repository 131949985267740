import React from "react";
import { Link } from "react-router-dom";

const BillingPlanPage = ({ plans }) => {
  return (
    <div className="get-plan-form bg-white rounded-lg mobXS:mx-6 tabMD:mx-6 my-10 border desktop:w-1/4 desktop:mx-auto p-6 space-y-6 shadow-shadow5">
      <div className="title-subtitle space-y-3">
        <h6 className="text-h6 text-dark4 text-center">Pricing plan</h6>
      </div>
      <div className="plans-list flex flex-col items-center space-y-9">
        {plans &&
          plans.length > 0 &&
          plans.map((plan) => (
            <div key={plan.id} className="plan-item text-center space-y-6">
              <h6 className="plan-name">{plan.name}</h6>
              <p className="plan-price">Price: {plan.price / 100} $ / year</p>
              <div className="justify-center">
                <Link
                  key={plan.id}
                  className="border border-primary rounded bg-primary text-white px-[8px] py-[10px]"
                  to={`/dashboard/billing/plans/checkout/${plan.plan_id}`}
                >
                  Get started today
                </Link>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default BillingPlanPage;
