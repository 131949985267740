import React from "react";

const PlusIcon = () => {
  return (
    <div>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.9347 18.4351C17.2281 20.1416 14.9135 21.1004 12.5 21.1004C10.0866 21.1004 7.77193 20.1416 6.06535 18.4351C4.35877 16.7285 3.40002 14.4139 3.40002 12.0004C3.40002 9.58692 4.35877 7.2723 6.06535 5.56572C7.77193 3.85914 10.0866 2.90039 12.5 2.90039C14.9135 2.90039 17.2281 3.85914 18.9347 5.56572C20.6413 7.2723 21.6 9.58692 21.6 12.0004C21.6 14.4139 20.6413 16.7285 18.9347 18.4351ZM13.9 8.10039C13.9 7.72909 13.7525 7.37299 13.49 7.11044C13.2274 6.84789 12.8713 6.70039 12.5 6.70039C12.1287 6.70039 11.7726 6.84789 11.5101 7.11044C11.2475 7.37299 11.1 7.72909 11.1 8.10039V10.6004H8.60002C8.22872 10.6004 7.87263 10.7479 7.61007 11.0104C7.34752 11.273 7.20002 11.6291 7.20002 12.0004C7.20002 12.3717 7.34752 12.7278 7.61008 12.9903C7.87263 13.2529 8.22872 13.4004 8.60002 13.4004H11.1V15.9004C11.1 16.2717 11.2475 16.6278 11.5101 16.8903C11.7726 17.1529 12.1287 17.3004 12.5 17.3004C12.8713 17.3004 13.2274 17.1529 13.49 16.8903C13.7525 16.6278 13.9 16.2717 13.9 15.9004V13.4004H16.4C16.7713 13.4004 17.1274 13.2529 17.39 12.9903C17.6525 12.7278 17.8 12.3717 17.8 12.0004C17.8 11.6291 17.6525 11.273 17.39 11.0104C17.1274 10.7479 16.7713 10.6004 16.4 10.6004H13.9V8.10039Z"
          fill="white"
          stroke="white"
        />
      </svg>
    </div>
  );
};

export default PlusIcon;
