import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { AuthProvider } from "./contexts/AuthProvider";
import { RouterProvider } from "react-router-dom";
import router from "./routers";

const root = ReactDOM.createRoot(document.getElementById("root"));
console.log(router);
root.render(
  <AuthProvider>
    <RouterProvider router={router}></RouterProvider>
  </AuthProvider>,
);
reportWebVitals();
