import React, {useState } from "react";
import axios from "axios";
import ButtonSecondary from "../buttons/ButtonSecondary";
import axiosConfig from "../../axiosConfig";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import { Hourglass } from "react-loader-spinner";
import { useParams } from "react-router-dom";

const ModalUploadFile = ({ isOpen, onClose, selectedFolder }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [loading, setLoading] = useState(false); // Starea pentru loader
  const { folderNameParam } = useParams();

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUploadFile = async () => {
    setLoading(true);
    const accessToken = Cookies.get("AccessToken");
    const formData = new FormData();
    formData.append("file", selectedFile);
    const fileName = selectedFile.name;

    if (!accessToken) {
      return;
    }
    let presignedUrlResponse;
    try {
      if(fileName) {
        presignedUrlResponse = await axiosConfig(
            `file-manager/get-presigned-url?filename=${fileName}&pathtofolder=/`,
        );
      }
       if (folderNameParam) {
         presignedUrlResponse = await axiosConfig(
             `file-manager/get-presigned-url?filename=${fileName}&pathtofolder=/${folderNameParam}/`,
         );
       }
       if(selectedFolder) {
        presignedUrlResponse = await axiosConfig(
          `file-manager/get-presigned-url?filename=${fileName}&pathtofolder=/${selectedFolder}`,
        );
      }

      const presignedUrl = presignedUrlResponse.data.presignedUrl;

      const uploadResponse = await axios.put(presignedUrl, selectedFile);
      if (uploadResponse.status === 200) {
        setFileUploaded(true);
        toast.success("Success uploaded file");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      toast.error("Error to upload file", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
      <div
        className={`${uploadSuccess ? "bg-greenLight" : "bg-white"} p-8 rounded-lg mx-6`}
      >
        {uploadSuccess ? (
          <div>
            <p className="text-bodyMR text-dark4 mb-4 text-left">
              File uploaded successfully
            </p>
          </div>
        ) : (
          <div>
            <h2 className="text-h6 text-dark4 mb-4 text-left">Upload a file</h2>
            <input
              type="file"
              onChange={handleFileChange}
              className="p-2 w-full rounded mt-2"
            />
            {loading ? (
              <div className="flex flex-row justify-center items-center mt-10">
                <Hourglass
                  visible={true}
                  height="40"
                  width="40"
                  animationDuration="2"
                  ariaLabel="hourglass-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  colors={["#306cce", "#72a1ed"]}
                />
              </div>
            ) : (
              <div className="flex justify-center mt-4 space-x-6">
                <ButtonSecondary
                  text="Upload"
                  customClass="bg-primary text-white text-bodyMR p-1 px-[22px] py-[6px]"
                  onClick={handleUploadFile}
                />
                <ButtonSecondary
                  text="Cancel"
                  customClass="bg-white text-dark4 text-bodyMR border border-dark4 px-[22px] py-[6px]"
                  onClick={handleCancel}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default ModalUploadFile;
