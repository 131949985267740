import { useEffect, useState } from "react";
import axiosConfig from "../axiosConfig";
import { useLocation, useNavigate } from "react-router-dom";

const useSubscriptionCheck = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [plans, setPlans] = useState(null);

  useEffect(() => {
    axiosConfig
      .get("/get-plans")
      .then((responseSettings) => {
        const data = responseSettings.data;
        setPlans(data?.plans);
        console.log(data.isSubscribed);
        if (
          !data.isSubscribed &&
          location.pathname !== "/dashboard/billing/plans" &&
          location.pathname !==
            `/dashboard/billing/plans/checkout/${data.plans[0].plan_id}`
        ) {
          navigate("/dashboard/billing/plans");
        }
      })
      .catch((error) => {
        console.error("Error to get plans:", error);
      });
  }, [location.pathname]);
  return plans;
};

export default useSubscriptionCheck;
