import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import LoginPage from "../pages/LoginPage";
import { Route, Routes, useNavigate } from "react-router-dom";
import RegisterPage from "../pages/RegisterPage";
import HomePage from "../pages/HomePage";
import Cookies from "js-cookie";
import TermsConditionsPage from "../pages/TermsConditionsPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import CookiesPage from "../pages/CookiesPage";

const PublicLayout = () => {
  const accessToken = Cookies.get("AccessToken");
  const navigate = useNavigate();

  if (accessToken) {
    navigate("/dashboard");
  }
  return (
    <div className="page bg-gary1">
      <Header />
      <Routes>
        <Route path="*" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/terms-conditions" element={<TermsConditionsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/cookies" element={<CookiesPage />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default PublicLayout;
