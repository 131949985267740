import React from "react";
import PlusIcon from "../../icons/PlusIcon";

const ButtonAddNew = ({ className, textButton, onClick, textSize}) => {
  return (
    <div>
      <button
        className={`border border-primary bg-primary ${className} rounded px-[24px] py-[8px] flex flex-row space-x-2 items-center`}
        onClick={onClick}
      >
        <PlusIcon />
        <p className={`text-white text-center text-bodyMR ${textSize}`}>{textButton}</p>
      </button>
    </div>
  );
};

export default ButtonAddNew;
