import React, { useEffect, useState } from "react";
import SortBy from "./SortBy";
import { toast, ToastContainer } from "react-toastify";
import axiosConfig from "../../../axiosConfig";
import { initTWE, Tab } from "tw-elements";
import RightContentMessages from "../../messages/RightContentMessages";
import { Hourglass } from "react-loader-spinner";

const ContentMessages = () => {
  const [isNoMessageVisible, setIsNoMessageVisible] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initTWE({ Tab });
  }, []);

  const handleDiscardChangesClick = () => {
    setIsModalOpen(true);
  };

  const handleTabSelect = (messageId) => {
    setSelectedTab(messageId);
  };
  const handleMailSelect = () => {
    setIsNoMessageVisible(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get("/outbox/get-messages")
      .then((response) => {
        const fetchedMessages = response.data.messages;
        setMessages(fetchedMessages);
      })
      .catch((error) => {
        toast.error("Error fetching data", error.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const extractInitials = (name) => {
    const words = name.split(" ");
    const initials = words.map((word) => word.charAt(0).toUpperCase());
    return initials.join("");
  };

  const formatMessageBody = (body) => {
    const plainText = body.replace(/<[^>]+>/g, "");
    return plainText.length > 30
      ? plainText.substring(0, 30) + "..."
      : plainText;
  };
  const escapeHTML = (body) => {
    return body.replace(/<[^>]+>/g, "");
  };

  return (
    <div className="desktop:flex desktop:flex-row gap-6 desktop:h-full">
      <div className="card-mail-preview desktop:w-[40%] cursor-pointer desktop:rounded-bl-2xl desktop:rounded-br-2xl">
        <div className="tools-bar flex flex-row p-6  bg-white  desktop:rounded-tl-2xl desktop:rounded-tr-2xl justify-between mobile:hidden">
          <p className="text=bodyMR text-black">{"My Message"}</p>
          {/*<SortBy*/}
          {/*  className="mobile:hidden"*/}
          {/*  items={["Date", "Recipient name", "Subject"]}*/}
          {/*/>*/}
        </div>
        {/*left messages desktop tab*/}
        <div
          className="mails-desktop mobile:hidden bg-white overflow-auto desktop:h-[76%] desktop:rounded-bl-2xl desktop:rounded-br-2xl"
          onClick={handleMailSelect}
          role="tablist"
        >
          {loading ? (
            <div className="flex flex-row justify-center items-center mt-10">
              <Hourglass
                visible={true}
                height="40"
                width="40"
                animationDuration="2"
                ariaLabel="hourglass-loading"
                wrapperStyle={{}}
                wrapperClass=""
                colors={["#306cce", "#72a1ed"]}
              />
            </div>
          ) : (
            messages &&
            messages.map((message) => (
              <a
                key={message.id}
                className={`mail items-center flex flex-row gap-3 py-6 px-6 hover:bg-gary1 ${selectedTab === message.id ? "data-[twe-tab-active]" : ""}`}
                href={`#${message.id}`}
                data-twe-toggle="pill"
                data-twe-target={`#${message.id}`}
                aria-controls={`${message.id}`}
                aria-selected={selectedTab === message.id ? "true" : "false"}
                role="tab"
                onClick={() => handleTabSelect(message.id)}
              >
                <div>
                  <h6 className="border w-[45px] text-center bg-primary text-white p-2 rounded-full">
                    {extractInitials(message.label)}
                  </h6>
                </div>
                <div className="flex flex-col space-y-1">
                  <p className="text-bodyMR text-dark2">{message.label}</p>
                  <div className="flex flex-row gap-4">
                    <p className="hidden text-redDark text-bodyESR bg-redLight p-1 text-center rounded-3xl w-[45px]">
                      Draft
                    </p>
                    <p className="text-bodySM text-dark4">{message.subject}</p>
                  </div>
                  <p className="text-bodyESR text-dark5">
                    {formatMessageBody(message.body)}
                  </p>
                </div>
                <img
                  src="/ui/pinGreen.svg"
                  alt=""
                  className="hidden w-[16px] ml-auto mb-auto"
                />
              </a>
            ))
          )}
        </div>
        <div className="bg-white desktop:hidden overflow-auto desktop:h-[76%] desktop:rounded-bl-2xl desktop:rounded-br-2xl">
          {messages &&
            messages.map((message) => (
              <a
                className="mail items-center flex flex-row gap-3 py-6 px-6 hover:bg-gary1"
                href={`/ui/dashboard/preview/${message.id}`}
              >
                <div>
                  <h6 className="border w-[45px] text-center bg-primary text-white p-2 rounded-full">
                    {extractInitials(message.label)}
                  </h6>
                </div>
                <div className="flex flex-col space-y-1">
                  <p className="text-bodyMR text-dark2">{message.label}</p>
                  <div className="flex flex-row gap-4">
                    <p className="hidden text-redDark text-bodyESR bg-redLight p-1 text-center rounded-3xl w-[45px]">
                      Draft
                    </p>
                    <p className="text-bodySM text-dark4">{message.subject}</p>
                  </div>
                  <p className="text-bodyESR text-dark5">
                    {formatMessageBody(message.body)}
                  </p>
                </div>
                <img
                  src="/ui/pinGreen.svg"
                  alt=""
                  className="hidden w-[16px] ml-auto mb-auto"
                />
              </a>
            ))}
        </div>
      </div>
      {/*right messages content*/}
      <RightContentMessages
        messages={messages}
        selectedTab={selectedTab}
        handleDiscardChangesClick={handleDiscardChangesClick}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        handleTabSelect={handleTabSelect}
        handleMailSelect={handleMailSelect}
        isNoMessageVisible={isNoMessageVisible}
        escapeHTML={escapeHTML}
      />
      <ToastContainer
        position="top-center"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default ContentMessages;
