import React, { useState } from "react";
import {
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
} from "@stripe/react-stripe-js";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import Loader, { Hourglass } from "react-loader-spinner"; // Importă Loader-ul

const CheckoutPage = ({ intent }) => {
  const stripe = useStripe();
  const elements = useElements();
  const params = useParams();
  const navigate = useNavigate();
  const planId = params.planId;
  const [loading, setLoading] = useState(false); // State-ul pentru loader

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Setează loading pe true când utilizatorul face submit

    const accessToken = Cookies.get("AccessToken");

    if (!stripe || !elements) {
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });
    const paymentMethod = payload.paymentMethod.id;
    console.log(paymentMethod);

    if (payload.error) {
      toast.error(payload.error.message);
      setLoading(false);
    } else {
      try {
        if (!accessToken) {
          return;
        }
        const config = {
          withCredentials: true,
        };
        const { REACT_APP_BASE } = process.env;
        const response = await axios.post(
          `${REACT_APP_BASE}api/actions/plans/process`,
          {
            paymentMethod,
            planId,
          },
          config,
        );
        if (response.data.success) {
          navigate("/dashboard/success");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          toast.error("Payment failed", response.data.error);
        }
      } catch (error) {
        toast.error("Error processing payment", error.data);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="get-plan-form bg-white rounded-lg mobXS:mx-6 tabMD:mx-6 my-10 border desktop:w-1/4 desktop:mx-auto p-6 space-y-6 shadow-shadow5">
      <h6 className="text-h6 text-dark4">Payment details</h6>
      <p className="text-bodyMR text-black">
        You will be charged{" "}
        <span className="text-bodyMS text-primary">12$</span>.Billed annually.
      </p>
      <div className="flex flex-col space-y-9">
        <form onSubmit={handleSubmit} className="space-y-9">
          <div className="form-group">
            <label htmlFor="card-number" className="text-bodyMM text-dark1">
              Card Number
            </label>
            <CardNumberElement
              id="card-number"
              className="border rounded p-3"
            />
          </div>
          <div className="form-group">
            <label htmlFor="card-expDate" className="text-bodyMM text-dark1">
              Expiry Date
            </label>
            <CardExpiryElement
              id="card-expDate"
              className="border rounded p-3"
            />
          </div>
          <div className="form-group">
            <label htmlFor="card-cvv" className="text-bodyMM text-dark1">
              CVV
            </label>
            <CardCvcElement id="card-cvv" className="border rounded p-3" />
          </div>
          <button
            type="submit"
            className="border bg-primary border-primary rounded px-[8px] py-[10px] text-white"
            disabled={!stripe}
          >
            Complete subscription
          </button>
        </form>
        {loading && ( // Afișează Loader-ul dacă loading este true
          <div className="flex flex-row justify-center items-center mt-10">
            <Hourglass
              visible={true}
              height="40"
              width="40"
              animationDuration="2"
              ariaLabel="hourglass-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors={["#306cce", "#72a1ed"]}
            />
          </div>
        )}
        <ToastContainer
          position="top-center"
          autoClose={7000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
};

export default CheckoutPage;
