import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { Elements } from "@stripe/react-stripe-js";
import { Route, Routes, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import MyFilesPage from "../pages/MyFilesPage";
import SettingsPage from "../pages/SettingsPage";
import BillingInfo from "../pages/BillingInfo";
import MessagesPage from "../pages/MessagesPage";
import HeaderDashboard from "../components/dashboard/header/HeaderDashboard";
import MyCards from "../pages/MyCards";
import Search from "../components/dashboard/header/Search";
import MyMessagesPage from "../pages/MyMessagesPage";
import CheckoutPage from "../pages/CheckoutPage";
import SuccessPage from "../pages/SuccessPage";
import useSubscriptionCheck from "../contexts/useSubscriptionCheck";
import BillingPlanPage from "../pages/BillingPlanPage";
import CreateNewMessage from "../pages/CreateNewMessage";
import EditMessage from "../pages/EditMessage";
import PreviewMessageMobile from "../pages/PreviewMessageMobile";

const ProtectedLayout = () => {
  const { REACT_APP_STRIPE_KEY } = process.env;
  const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);
  const accessToken = Cookies.get("AccessToken");
  const plans = useSubscriptionCheck();
  const navigate = useNavigate();

  if (!accessToken) {
    navigate("/login");
  }
  useEffect(() => {
    const isTokenExpired = () => {
      const token = Cookies.get("AccessToken");
      if (!token) {
        navigate("/login");
        return true;
      }

      const tokenPayload = JSON.parse(atob(token.split(".")[1]));
      const expirationTime = tokenPayload.exp * 1000;

      return expirationTime < Date.now();
    };

    if (isTokenExpired()) {
      navigate("/login");
    }
  }, [navigate]);
  return (
    <>
      <div>
        <div className="flex mobile:flex-col">
          <HeaderDashboard />
          <div className="right-side-desktop h-screen bg-gray2 w-full flex flex-col">
            <div className="search bg-white mobile:hidden">
              <Search />
            </div>

            <div className="content-page desktop:px-9 desktop:pt-9 desktop:h-full overflow-hidden">
              <Routes>
                <Route path="" element={<MessagesPage />} />
                <Route path="/create/show" element={<CreateNewMessage />} />
                <Route path="/edit/show/:messageId" element={<EditMessage />} />
                <Route
                  path="/preview/:messageId"
                  element={<PreviewMessageMobile />}
                />
                <Route path="my-files/*" element={<MyFilesPage />} />
                <Route path="settings" element={<SettingsPage />} />
                <Route path="billing" element={<BillingInfo />} />
                <Route path="billing/my-cards" element={<MyCards />} />
                <Route
                  path="billing/plans"
                  element={<BillingPlanPage plans={plans} />}
                />
                <Route
                  path="billing/plans/checkout/:planId"
                  element={
                    <Elements stripe={stripePromise}>
                      <CheckoutPage />
                    </Elements>
                  }
                />{" "}
                <Route path="/my-messages" element={<MyMessagesPage />} />
                <Route path="success" element={<SuccessPage />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProtectedLayout;
