import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../components/form/Input";
import { ToastContainer, toast } from "react-toastify";
import ButtonPrimary from "../components/buttons/ButtonPrimary";
import ButtonSecondary from "../components/buttons/ButtonSecondary";
import "react-toastify/dist/ReactToastify.css";
import { loginFields } from "../constants/formFields";
import { useAuth } from "../contexts/AuthProvider";
import Cookies from "js-cookie";
import Loader, { Hourglass } from "react-loader-spinner";

const LoginPage = () => {
  const [rememberMe, setRememberMe] = useState(false);
  const [loginState, setLoginState] = useState(loginFields);
  const navigate = useNavigate();
  const { accessToken } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleChange = (e, index) => {
    setLoginState((prevState) => {
      const updatedState = [...prevState];
      updatedState[index]["value"] = e.target.value;
      return updatedState;
    });
  };

  // login user
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await accessToken();

      if (response.status === 200) {
        const { accessToken } = response.data;
        Cookies.set("AccessToken", accessToken, { expires: 1 / 24 });
        navigate("/dashboard");
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      }
      if (error.response && error.response.status === 404) {
        if (error.response.data && error.response.data.errors) {
          const errors = Object.values(error.response.data.errors);
          errors.forEach((error) => {
            toast.error(error[0]);
          });
        } else {
          toast.error("Resource not found");
        }
      }

      if (error.response && error.response.status === 422) {
        const errors = Object.values(error.response.data.errors);
        errors.forEach((error) => {
          toast.error(error[0]);
        });
      } else {
        toast.error(error);
      }
      setLoading(false);
    }
  };

  const handleCheckboxChange = (e) => {
    setRememberMe(e.target.checked);
  };

  return (
    <div className="card-login bg-white rounded-lg mobXS:mx-6 tabMD:mx-6 my-10 border desktop:w-1/4 desktop:mx-auto">
      <h6 className="text-h6 text-dark4 text-center py-12 px-8">
        Log in to your account
      </h6>
      {loading ? (
        <div className="flex flex-row justify-center items-center left-0 right-0 mx-auto pb-20">
          <Hourglass
            visible={true}
            height="40"
            width="40"
            animationDuration="2"
            ariaLabel="hourglass-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={["#306cce", "#72a1ed"]}
          />
        </div>
      ) : (
        <form className="space-y-6" onSubmit={handleLogin}>
          <div className="space-y-px desktop:w-full flex justify-center">
            <div className="mobXS:w-full tabMD:w-1/2 desktop:w-full tabMD:items-center">
              {loginState.map((field, index) => (
                <Input
                  key={field.id}
                  handleChange={(e) => handleChange(e, index)}
                  value={field.value}
                  labelText={field.labelText}
                  labelFor={field.labelFor}
                  id={field.id}
                  name={field.name}
                  type={field.type}
                  isRequired={field.isRequired}
                  placeholder={
                    typeof field.placeholder === "string"
                      ? field.placeholder
                      : undefined
                  }
                  customClass="rounded-lg"
                />
              ))}
              <div className="flex items-center mx-6">
                <input
                  type="checkbox"
                  className="rounded border-stroke text-primary"
                  checked={rememberMe}
                  onChange={handleCheckboxChange}
                />
                <p className="text-dark1 text-bodyMM ml-3">Remember me</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-6 px-6 mobXS:items-start mobXS:justify-self-start tabMD:items-center tabMD:justify-self-center">
            <ButtonSecondary
              text="Sign in"
              type="submit"
              bgColor="bg-secondary"
              color="text-white"
              padding="mobXS:py-[10px] mobXS:px-[24px] tabMD:py-[10px] tabMD:px-[24px]"
              customClass={`rounded-[25px]`}
              fontsize="text-bodyMR"
              width="mobXS:w-full tabMD:w-1/2 desktop:w-full"
            />
            <ButtonSecondary
              borderColor="border-primary"
              text="Sign in with Google"
              fontsize="text-bodyMR"
              padding="mobXS:py-[10px] mobXS:px-[24px] tabMD:py-[10px] tabMD:px-[24px]"
              color="text-dark3"
              bgColor="bg-white"
              width="mobXS:w-full tabMD:w-1/2 desktop:w-full"
              iconType="google"
              customClass="justify-center hidden"
            />
            <ButtonSecondary
              borderColor="border-primary"
              text="Sign in with Apple"
              fontsize="text-bodyMR"
              padding="mobXS:py-[10px] mobXS:px-[24px] tabMD:py-[10px] tabMD:px-[24px]"
              color="text-dark3"
              bgColor="bg-white"
              width="mobXS:w-full tabMD:w-1/2 desktop:w-full"
              iconType="apple"
              customClass="justify-center hidden"
            />
          </div>
          <div className="flex justify-center">
            <a className="text-primary text-bodyMM" href="/">
              Forgot password
            </a>
          </div>
          <div className="flex justify-center pb-10">
            <p className="text-dark6 text-bodyMM">
              Don’t have an account?
              <a className="text-primary text-bodyMM ml-2" href="ui/register">
                Sign up now
              </a>
            </p>
          </div>
        </form>
      )}
      <ToastContainer
        position="top-center"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default LoginPage;
