import React from "react";
import TrashIcon from "../../icons/TrashIcon";

const ButtonDelete = ({ className, deleteAction }) => {
  return (
    <div>
      <button
        className={`border rounded border-redDark ${className} w-full py-[8px] flex flex-row justify-center gap-2`}
        onClick={deleteAction}
      >
        <TrashIcon className="pt-1" />
        <p className="text-bodyMR text-redDark">Delete</p>
      </button>
    </div>
  );
};

export default ButtonDelete;
