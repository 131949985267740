import CloseIcon from "../../../icons/CloseIcon";
import OpenIcon from "../../../icons/OpenIcon";

export default function ButtonMenu({ toggleMenu, isOpen, hidden }) {
  return (
    <button
      id="closeButton"
      className={`focus:outline-none ${hidden}`}
      onClick={toggleMenu}
    >
      {isOpen ? <CloseIcon /> : <OpenIcon />}
    </button>
  );
}
