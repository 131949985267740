import React, { useState } from "react";
import ButtonSecondary from "../buttons/ButtonSecondary";
import axiosConfig from "../../axiosConfig";
import { toast } from "react-toastify";
import Loader, { Hourglass } from "react-loader-spinner";

const ModalConfirmSubscriptions = ({
  isOpenModalSubscription,
  closeModal,
  titleModal,
  textModal,
  renewalDate,
  isSubscriptionCanceled,
  setRenewalDate,
  setIsSubscriptionCanceled,
}) => {
  const [loading, setLoading] = useState(false);

  const handleCloseModal = () => {
    closeModal();
  };
  const cancelSubscription = () => {
    setLoading(true);
    axiosConfig
      .post("/billing/subscription-action")
      .then((response) => {
        const { isCanceled, renewalDate } = response.data;
        if (isCanceled === false) {
          setRenewalDate(renewalDate);
        } else {
          setIsSubscriptionCanceled(true);
        }
        toast.success("Canceled successfully!", response.data);
        setLoading(false);
        closeModal();
      })
      .catch((error) => {
        toast.error("Error canceling subscription", error.data);
        setLoading(false);
      });
  };
  const handleClickDelete = () => {
    cancelSubscription();
  };
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-lg mx-6">
        <h2 className="text-h6 text-dark4 mb-4 text-center">{titleModal}</h2>
        <p className="text-bodyLR text-dark4 text-center">{textModal}</p>
        <div className="flex-row flex gap-2 mobile:pt-8 desktop:pt-12 cursor-pointer justify-center">
          {loading ? (
            <div className="flex justify-center items-center mt-10">
              <Hourglass
                visible={true}
                height="40"
                width="40"
                animationDuration="2"
                ariaLabel="hourglass-loading"
                wrapperStyle={{}}
                wrapperClass=""
                colors={["#306cce", "#72a1ed"]}
              />
            </div>
          ) : (
            <>
              <ButtonSecondary
                text="Delete"
                customClass="bg-redDark text-white text-center justify-center"
                width="mobile:w-full desktop:w-1/4"
                padding="px-[5px] py-[10px]"
                onClick={handleClickDelete}
              />
              <ButtonSecondary
                text="Cancel"
                customClass="bg-white border text-dark4 text-center justify-center"
                width="mobile:w-full desktop:w-1/4"
                padding="px-[5px] py-[10px]"
                onClick={handleCloseModal}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmSubscriptions;
