import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ButtonEdit from "../components/buttons/ButtonEdit";
import axiosConfig from "../axiosConfig";
import { toast } from "react-toastify";
import DocumentIcon from "../icons/DocumentIcon";
import ImageIcon from "../icons/ImageIcon";
import MovieIcon from "../icons/MovieIcon";
import ZipDir from "../icons/ZipDir";
import Loader, { Hourglass } from "react-loader-spinner";

const PreviewMessageMobile = () => {
  const navigate = useNavigate();
  const { messageId } = useParams();
  const [message, setMessage] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (messageId && firstLoad) {
      setLoading(true);
      axiosConfig
        .get(`outbox/edit/get-details/${messageId}`)
        .then((response) => {
          const fetchedMessage = response.data.message;
          setMessage(fetchedMessage);

          if (
            fetchedMessage.file_paths &&
            fetchedMessage.file_paths.length > 0
          ) {
            const attachmentNames = fetchedMessage.file_paths.map((file) => {
              const pathParts = file.path.split("/");
              return pathParts[pathParts.length - 1];
            });
            setAttachments((prevAttachments) => [
              ...prevAttachments,
              ...attachmentNames,
            ]);
          }
        })
        .catch((error) => {
          toast.error("Error fetching data", error.data);
        })
        .finally(() => {
          setLoading(false);
          setFirstLoad(false);
        });
    }
  }, [firstLoad, messageId]);

  const escapeHTML = (body) => {
    return body.replace(/<[^>]+>/g, "");
  };

  const getExtension = (fileName) => {
    const parts = fileName.split(".");
    if (parts.length >= 2) {
      const extension = parts[parts.length - 1];
      return extension.toLowerCase();
    } else {
      return "";
    }
  };
  const handleEditMessage = (messageId) => {
    navigate(`/dashboard/edit/show/${messageId}`);
  };
  const filteredAttachments = attachments.filter(attachment => attachment !== '');
  return (
    <>
      {loading ? (
        <div className="flex flex-row justify-center items-center mt-10">
          <Hourglass
            visible={true}
            height="40"
            width="40"
            animationDuration="2"
            ariaLabel="hourglass-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={["#306cce", "#72a1ed"]}
          />
        </div>
      ) : (
        <>
          {message && (
            <>
              <div className="desktop:hidden flex flex-row justify-between bg-white p-6 cursor-pointer">
                <a
                  className="flex flex-row items-center space-x-2"
                  href="/ui/dashboard"
                >
                  <img src="/ui/back.svg" alt="back" />
                  <p className="text-primary text-bodyMM">Back</p>
                </a>
                <ButtonEdit
                  textButton="Edit Message"
                  className="text-primary cursor-pointer"
                  onClick={() => handleEditMessage(message.id)}
                />
              </div>
              <div className={`message-preview flex-col h-full  p-9`}>
                <div className={`message-content`} role="tabpanel">
                  <div className="header space-y-6">
                    <div className="header-sb-mail flex flex-row justify-between">
                      <p className="text-bodyLM text-black">{message.label}</p>
                    </div>
                    <div className="header-to-mail border-b-2 flex flex-row space-x-2">
                      <p className="text-bodySR text-black">To:</p>
                      <p className="email-address text-bodySR text-dark5">
                        {message.recipient_email}
                      </p>
                    </div>
                  </div>
                  <div className="content-mail pt-6">
                    <textarea
                      readOnly={true}
                      style={{ resize: "none" }}
                      className="border-2 mobile:h-auto mobile:w-[320px] bg-gray2 rounded-lg border-gray2 focus:ring-transparent focus:ring-offset-0 focus:ring-0 focus:border-gray2"
                    >
                      {escapeHTML(message.body)}
                    </textarea>
                  </div>
                </div>
                {filteredAttachments.length > 0 && (
                  <div className={`attachments ${filteredAttachments.length === 0 ? 'desktop:bg-gray2' : 'desktop:bg-white'} mt-10 mobile:bg-white desktop:bg-gray2 rounded-lg  py-5 flex flex-wrap`}>
                    {filteredAttachments.map((attachment, index) => (
                      <div className="element-at cursor-pointer pl-5" key={index} style={{ flex: '0 0 calc(50% - 8px)' }}>
                          {["doc", "docx", "txt", "pdf"].includes(
                            getExtension(attachment),
                          ) ? (
                            <div className="flex flex-row items-center">
                              <DocumentIcon
                                className="mobile:hidden"
                                dimensions={{ width: 106, height: 90 }}
                              />
                              <DocumentIcon
                                className="desktop:hidden"
                                dimensions={{ width: 34, height: 34 }}
                              />
                              <p className="text-bodySR text-dark3">
                                {attachment}
                              </p>
                            </div>
                          ) : null}

                          {["jpg", "jpeg", "png"].includes(getExtension(attachment)) ? (
                            <div className="flex flex-row items-center">
                              <ImageIcon
                                className="mobile:hidden"
                                dimensions={{ width: 106, height: 90 }}
                              />
                              <ImageIcon
                                className="desktop:hidden"
                                dimensions={{ width: 34, height: 34 }}
                              />
                              <p className="text-bodySR text-dark3">
                                {attachment}
                              </p>
                            </div>
                          ) : null}

                          {["mp4"].includes(getExtension(attachment)) ? (
                            <div className="flex flex-row items-center">
                              <MovieIcon
                                className="mobile:hidden"
                                dimensions={{ width: 106, height: 90 }}
                              />
                              <MovieIcon
                                className="desktop:hidden"
                                dimensions={{ width: 34, height: 34 }}
                              />
                              <p className="text-bodySR text-dark3">
                                {attachment}
                              </p>
                            </div>
                          ) : null}

                          {["zip"].includes(getExtension(attachment)) ? (
                            <div className="flex flex-row items-center">
                              <ZipDir
                                className="mobile:hidden"
                                dimensions={{ width: 106, height: 90 }}
                              />
                              <ZipDir
                                className="desktop::hidden"
                                dimensions={{ width: 34, height: 34 }}
                              />
                              <p className="text-bodySR text-dark3">
                                {attachment}
                              </p>
                            </div>
                          ) : null}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default PreviewMessageMobile;
