import ButtonMenu from "../elements/ButtonMenu";
import LogoHeader from "../elements/LogoHeader";
import DynamicNav from "../../nav/DynamicNav";
import { useState } from "react";
import {Link, useNavigate} from "react-router-dom";

export default function Mobile() {
  const navLinks = [
    { src: null, url: "/", text: "Get Started" },
    { src: null, url: "/", text: "Pricing" },
    { src: null, url: "/", text: "Security" },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleRegister = () => {
    navigate("/register");
  };

  return (
    <>
      <div className="grid gap-2 grid-cols-3 p-5 desktop:hidden">
        <div className="flex items-start justify-start">
          <ButtonMenu toggleMenu={toggleMenu} isOpen={isOpen} />
        </div>
        <LogoHeader />
      </div>

      <div
        className={`bg-white  w-full h-full border p-5 ${isOpen ? "" : "hidden"}`}
      >
        <div className="flex flex-col gap-y-6 items-start justify-self-start">
          <DynamicNav links={navLinks} navigateTo={false} />
          <div className="flex">
            <a href="/ui/login" className="text-primary">
              Login
            </a>
          </div>
          <Link to={`/ui/register`} className={`w-1/2 h-[50px] rounded-[25px] bg-secondary flex items-center justify-center`}>
            <p className={`text-white text-center`}>Create account</p>
          </Link>
        </div>
      </div>
    </>
  );
}
