import ButtonPrimary from "../../../buttons/ButtonPrimary";
import { useNavigate } from "react-router-dom";

export default function ContentText() {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/register");
  };
  return (
    <div className="">
      <h1 className="desktop:text-h3 mobile:text-h4 mobile:p-6  text-dark3 mobile:text-center">
        Do you have words left unsaid, untold stories, or treasures?
      </h1>
      <p className="desktop:text-bodyLS mobile:text-bodyMR mobile:px-6 mobile:pb-6 text-dark5 desktop:p-myCustomP3 mobile:text-center xl:text-start">
        <span className="text-bodyLS text-primary">Testament.digital</span>{" "}
        helps you share them with your loved ones after you’re gone.
      </p>
      <div className="mobXS:px-6 tabMD:items-center tabMD:justify-center desktop:justify-start desktop:items-start flex pb-6 cursor-pointer">
        <ButtonPrimary
          text="Get Started Now"
          width="mobXS:w-full"
          padding="mobXS:py-[14px] tabMD:py-[14px] tabMD:px-[24px] desktop:px-[38px] desktop:py-[14px]"
          fontsize="text-bodyLR"
          onClick={handleNavigate}
        />
      </div>
    </div>
  );
}
