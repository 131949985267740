import React from "react";
import MessageSelected from "./MessagesSelected";
const RightContentMessages = ({
  messages,
  selectedTab,
  handleDiscardChangesClick,
  escapeHTML,
  isNoMessageVisible,
}) => {
  return (
    <div className="preview-message desktop:w-[60%] desktop:h-[85%] bg-white rounded-2xl mobile:hidden">
      {isNoMessageVisible ? (
        <div className="no-message p-9 ">
          <div className="flex flex-col items-center">
            <div>
              <img src="/envelopes.svg" className="" alt="" />
            </div>
            <h6 className="text-dark4 text-h6">No message selected</h6>
            <p className="test-bodySR text-dark6">Select or create a message</p>
          </div>
        </div>
      ) : (
        <>
          <MessageSelected
            messages={messages}
            selectedTab={selectedTab}
            handleDiscardChangesClick={handleDiscardChangesClick}
            escapeHTML={escapeHTML}
          />
        </>
      )}
    </div>
  );
};

export default RightContentMessages;
