import AppleIcon from "../../icons/AppleIcon";
import GoogleIcon from "../../icons/GoogleIcon";

export default function ButtonSecondary({
  text,
  onClick,
  width,
  padding,
  borderColor,
  bgColor,
  color,
  customClass,
  fontsize,
  iconType,
}) {
  return (
    <button
      className={` ${customClass} ${borderColor} ${width} rounded-md ${bgColor} ${color} ${padding}`}
      onClick={onClick}
    >
      {iconType === "apple" && <AppleIcon />}
      {iconType === "google" && <GoogleIcon />}
      <p className={`${fontsize} text-center items-center`}>{text}</p>{" "}
    </button>
  );
}
