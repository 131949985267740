import React from "react";
import ApproveIcon from "../../icons/ApproveIcon";

const ButtonApprove = ({ onClick, className }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <div>
      <button
        className={`border rounded border-primary bg-primary ${className} w-[120px] py-[8px] flex flex-row justify-center items-center space-x-2`}
        onClick={handleClick}
      >
        <ApproveIcon />
        <p className="text-white text-center text-bodyMR">Approve</p>
      </button>
    </div>
  );
};

export default ButtonApprove;
