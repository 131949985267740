import React, { useState } from "react";
import ButtonMenu from "../../header/elements/ButtonMenu";
import DynamicNav from "../../nav/DynamicNav";
import { useNavigate } from "react-router-dom";
import LogoHeader from "../../header/elements/LogoHeader";
import Search from "./Search";
import LogoIcon from "../../../icons/LogoIcon";

const HeaderDashboard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navLinks = [
    { src: "/ui/envelope.svg", url: "/dashboard", text: "My messages" },
    { src: "/ui/folderDark.svg", url: "/dashboard/my-files", text: "My files" },
    { src: "/ui/settings.svg", url: "/dashboard/settings", text: "Settings" },
    { src: "/ui/doc.svg", url: "/dashboard/billing", text: "Billing info" },
  ];
  const navigate = useNavigate();
  const handleLogout = () => {
    document.cookie =
      "AccessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    navigate("/login");
  };
  const handleToggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="header-menu">
      {/*HEADER MENU MOBILE*/}
      <div
        className={`${isOpen ? "hidden" : ""} p-6 border-b-2 desktop:hidden`}
      >
        <div className="grid grid-cols-3">
          <div className="flex bg-white">
            <div className="flex items-start justify-start">
              <ButtonMenu toggleMenu={handleToggleMenu} isOpen={isOpen} />
            </div>
          </div>
          <div className="flex items-center justify-center">
            {!isOpen && <LogoHeader />}
          </div>
        </div>
      </div>
      {/*LEFT SIDE COMPONENT*/}
      <div
        className={`flex flex-col mobile:absolute mobile:translate-x-${isOpen ? "[300px]" : "[-300px]"} mobile:${!isOpen ? "hidden" : ""} bg-white shadow-shadow5 border pl-6 py-6 pr-3 mobile:w-2/3 desktop:w-[300px] z-20 h-screen`}
      >
        <div>
          <div className="logo2 grid grid-cols-2">
            <div className="flex items-start justify-start">
              <LogoIcon />
            </div>
            <div className="flex items-end justify-end">
              <div className="flex items-end justify-end">
                <ButtonMenu
                  hidden="desktop:hidden"
                  toggleMenu={handleToggleMenu}
                  isOpen={isOpen}
                />
              </div>
            </div>
          </div>
          <div className={`menu flex flex-col pt-16 pb-64 space-y-6`}>
            <DynamicNav
              links={navLinks}
              hover="hover:border hover:bg-gray2 hover:rounded-lg"
              navigateTo={true}
            />
            <div className="basis-1/4 flex ml-4 cursor-pointer desktop:hidden">
              <img src="/ui/mini.svg" />
              <a
                className="text-primary text-bodyMM pl-4"
                onClick={handleLogout}
              >
                Log out
              </a>
            </div>
          </div>
        </div>

        <div className="storage flex flex-col items-start space-y-2 mx-6 mt-auto">
          <p className="mr-2 text-bodyMR text-black">Storage</p>
          <div className="relative w-full h-2 bg-gray-200 rounded-full">
            <div
              className="absolute top-0 left-0 h-2 bg-[#FF5400] rounded-full"
              style={{ width: "23%" }}
            ></div>
          </div>
          <p className="text-bodyESR text-gray5">
            <span className="text-primary text-bodyESM">6.2 GB</span> used of 20
            GB (23%)
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeaderDashboard;
