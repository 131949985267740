import React from "react";
import ToggleButton from "../components/buttons/ToggleButton";
import ButtonDelete from "../components/buttons/ButtonDelete";

const MyCards = () => {
  return (
    <div>
      <div className="desktop:ml-[300px] desktop:p-[30px] mobile:p-6 desktop:bg-gray2">
        <a href="/dashboard/billing" className="flex flex-row cursor-pointer">
          <img src="/ui/arrow-left.svg" alt="" />
          <h6 className="text-h6 text-dark4">My Cards</h6>
        </a>
      </div>
      <div className="bg-gray2 mobile:p-6">
        <div className="flex-row flex gap-4">
          <img src="/ui/mastercard.svg" alt="" />
          <div className="flex flex-col">
            <p className="text-bodyMR text-dark4">MASTERCARD **** 1234</p>
            <p className="text-bodyESR text-dark6">Expires at: February 2026</p>
          </div>
        </div>
        <div className="flex flex-row pt-8 pb-10">
          <div className="flex flex-col">
            <p className="text-bodyMM text-black">Main card</p>
            <p className="text-bodyESR text-dark6">
              Choose this card to be your main card
            </p>
          </div>
          <div className="ml-auto">
            <ToggleButton />
          </div>
        </div>
        <ButtonDelete />
      </div>
    </div>
  );
};

export default MyCards;
