import React from "react";

const SuccessPage = () => {
  return (
    <div className="desktop:w-1/2 flex flex-row desktop:mx-auto  justify-center  bg-white rounded-2xl p-10 mt-20 mx-6">
      <div className="payment-success-page space-y-4">
        <h6 className="payment-success-page-title text-center text-primary text-h6">
          Payment Successful!
        </h6>
        <p className="payment-success-page-text text-center text-primary">
          Thank you for your payment. Your transaction was successful.
        </p>
      </div>
    </div>
  );
};

export default SuccessPage;
