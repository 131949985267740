import LogoHeader from "../elements/LogoHeader";
import DynamicNav from "../../nav/DynamicNav";
import ButtonPrimary from "../../buttons/ButtonPrimary";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import {Link, useNavigate} from "react-router-dom";
import ButtonSecondary from "../../buttons/ButtonSecondary";

export default function Desktop() {
  const navLinks = [
    { src: null, url: "/", text: "Get Started" },
    { src: null, url: "/", text: "Pricing" },
    { src: null, url: "/", text: "Security" },
  ];
  const navigate = useNavigate();

  const handleRegister = () => {
    navigate("/register");
  };
  return (
    <div className="xl:container xl:px-16 xxl:px-0 xxl:mx-auto mobile:hidden py-5">
      <div className="grid grid-cols-3">
        <div className="">
          <LogoHeader />
        </div>
        <div className="flex justify-end xl:gap-x-4 xxl:gap-x-12">
          <DynamicNav links={navLinks} navigateTo={true} />
        </div>
        <div className="flex justify-end">
          <div className="flex p-myCustomP1">
            <a href="/ui/login" className="text-dark5 hover:text-secondary ">
              Login
            </a>
          </div>
          <Link to={`register`} className={`w-[175px] h-[35px] rounded-[25px] bg-secondary flex items-center justify-center`}>
            <p className={`text-white text-center`}>Create account</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
