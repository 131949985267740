import { useState } from "react";
import EyeSlashIcon from "../../icons/EyeSlashIcon";
import EyeIcon from "../../icons/EyeIcon";

const fixedInputClass =
  "appearance-none block w-full px-3 py-2 mt-2 border border-stroke rounded-lg placeholder-dark6 text-dark1 focus:outline-none focus:stroke focus:stroke focus:z-10 sm:text-sm";

export default function Input({
  handleChange,
  value,
  labelText,
  labelFor,
  id,
  name,
  type,
  isRequired = false,
  placeholder,
  customClass,
}) {
  const isCheckbox = type === "checkbox";
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const inputType = showPassword ? "text" : type;

  return (
    <div className="mb-6 mx-6">
      {!isCheckbox && (
        <label htmlFor={labelFor} className="text-dark1 text-bodyMM">
          {labelText}
        </label>
      )}
      <div className="relative">
        <input
          onChange={handleChange}
          value={value}
          id={id}
          name={name}
          type={inputType}
          required={isRequired}
          className={fixedInputClass + customClass}
          placeholder={placeholder}
        />
        {type === "password" && (
          <button
            type="button"
            className="absolute   top-[10px] right-[10px] bg-transparent border-none"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <EyeSlashIcon color="text-stroke" />
            ) : (
              <EyeIcon color="text-stroke" />
            )}
          </button>
        )}
      </div>

      {isCheckbox && (
        <label htmlFor={labelFor} className="text-dark1 text-bodyMM ml-2">
          {labelText}
        </label>
      )}
    </div>
  );
}
