import React from "react";
import NavigationMyFiles from "../components/dashboard/utiles/NavigationMyFiles";
import ContentFiles from "../components/dashboard/utiles/ContentFiles";
import UseSelectedFoldersOrFiles from "../contexts/useSelectedFoldersOrFiles";
import { useParams } from "react-router-dom";

const MyFilesPage = () => {
  const {
    folderStructure,
    selectedFolders,
    handleCheckboxChange,
    getExtension,
    updateFolderStructure
  } = UseSelectedFoldersOrFiles();
  const { folderNameParam } = useParams();
  console.log(folderStructure, 'folderStructurefolderStructurefolderStructurefolderStructure')
  return (
    <div>
      <NavigationMyFiles
        selectedFolders={selectedFolders}
        folderStructure={folderStructure}
      />
      <ContentFiles
        folderStructure={folderStructure}
        selectedFolders={selectedFolders}
        handleCheckboxChange={handleCheckboxChange}
        getExtension={getExtension}
        updateFolderStructure={updateFolderStructure}
      />
    </div>
  );
};

export default MyFilesPage;
