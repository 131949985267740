const fixedInputClass =
  "appearance-none block mobXS:w-full tabMD:w-1/2 justify-center py-2 mt-2 border border-stroke rounded-lg placeholder-dark6 text-dark1 focus:outline-none focus:stroke focus:stroke focus:z-10 sm:text-sm";

export default function SettingsForm({
  handleChange,
  value,
  defaultValue = undefined,
  labelText,
  labelFor,
  id,
  type,
  name,
  min,
  max,
  isRequired = false,
  customClass,
  options,
}) {
  const actualValue = value !== undefined ? value : defaultValue;

  return (
    <div className="w-full">
      <label htmlFor={labelFor} className="text-dark1 text-bodyMM ml-2">
        {labelText}
      </label>
      {type === "number" ? (
        <input
          onChange={handleChange}
          value={actualValue}
          id={id}
          name={name}
          type={type}
          min={min}
          max={max}
          required={isRequired}
          className={`${fixedInputClass} ${customClass}`}
        />
      ) : (
        <select
          onChange={handleChange}
          value={actualValue}
          id={id}
          name={name}
          required={isRequired}
          className={`${fixedInputClass} ${customClass}`}
        >
          {options.map((option, index) => (
            <option
              key={option.id} // Adăugați cheia unică pentru fiecare opțiune
              value={option.value}
              defaultValue={option.value === actualValue} // Utilizați defaultValue în loc de selected
            >
              {option.label}
            </option>
          ))}
        </select>
      )}
    </div>
  );
}
