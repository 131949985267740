export default function Desktop() {
  return (
    <div className="xl:container xl:px-16 xxl:px-0 xxl:mx-auto pt-60 mobile:hidden">
      <div className="grid grid-cols-2">
        <h3 className="text-start text-h3 text-dark2">
          How do we know you've passed away?
        </h3>
        <p className="text-bodyLR text-dark5">
          Rely on us as your committed digital courier, ensuring the precise
          delivery of your messages and executing your digital testament just as
          you've planned.
        </p>
      </div>
      <div className="grid grid-cols-2 gap-12 pt-24">
        <div className="cards space-y-6 pt-12">
          <div className="card bg-bg1 border border-gary1 rounded-3xl p-6 relative">
            <img
              src="/ui/section-four/rectangle.svg"
              alt="arrow"
              className="absolute left-0 mobXS:bottom-[50px] tabMD:bottom-[35px]"
            />
            <p className="text-bodyMM text-dark5">
              Decide the pace of emails we will send over a specified number of
              days to verify if you are alive.
            </p>
          </div>
          <div className="card bg-bg1 border border-gary1 rounded-3xl p-6 relative">
            <img
              src="/ui/section-four/rectangle.svg"
              alt="arrow"
              className="absolute left-0 mobXS:bottom-[65px] tabMD:bottom-[35px]"
            />
            <p className="text-bodyMM text-dark5">
              If left unverified, our system intelligently triggers the delivery
              of all pending messages, ensuring your legacy is shared as
              planned.
            </p>
          </div>
        </div>
        <img
          src="/ui/section-four/young-woman.png"
          alt="young-woman"
          className="rounded-lg tabMD:w-full tabMD:h-full"
        />
      </div>
    </div>
  );
}
