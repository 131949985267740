import React from "react";
import NavigationMyMessages from "../components/dashboard/utiles/NavigationMyMessages";
import ContentMessages from "../components/dashboard/utiles/ContentMessages";

const MyMessagesPage = () => {
  return (
    <div className="flex flex-col  desktop:h-full desktop:space-y-10">
      <NavigationMyMessages />
      <ContentMessages />
    </div>
  );
};

export default MyMessagesPage;
