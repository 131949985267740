import SectionOne from "../sections/sections-one/SectionOne";
import SectionTwo from "../sections/sections-two/SectionTwo";
import SectionThree from "../sections/sections-three/SectionThree";
import SectionFour from "../sections/sections-four/SectionFour";
import SectionFive from "../sections/sections-five/SectionFive";
import SectionSix from "../sections/sections-six/SectionSix";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Cookies from "js-cookie";

export default function BodyPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = Cookies.get("AccessToken");
    if (accessToken) {
      navigate("/dashboard");
    }
  }, [navigate]);
  return (
    <section className="sections-page">
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <SectionSix />
    </section>
  );
}
