import React, { useEffect, useState } from "react";
import ButtonEdit from "../buttons/ButtonEdit";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../../axiosConfig";
import { toast } from "react-toastify";
import DocumentIcon from "../../icons/DocumentIcon";
import ImageIcon from "../../icons/ImageIcon";
import MovieIcon from "../../icons/MovieIcon";
import ZipDir from "../../icons/ZipDir";
import { Hourglass } from "react-loader-spinner";

const MessageSelected = ({
  messages,
  selectedTab,
  handleDiscardChangesClick,
}) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleEditMessage = (messageId) => {
    navigate(`edit/show/${messageId}`);
  };
  const extractTabNumberFromURL = () => {
    const url = window.location.href;
    const regex = /#(\d+)/;
    const match = url.match(regex);
    if (match) {
      return match[1];
    } else {
      return null;
    }
  };
  console.log(selectedTab);
  const messageId = extractTabNumberFromURL();
  useEffect(() => {
    if (messageId) {
      setLoading(true);
      axiosConfig
          .get(`outbox/edit/get-details/${messageId}`)
          .then((response) => {
            const fetchedMessage = response.data.message;
            setMessage(fetchedMessage);

            if (
                fetchedMessage.file_paths &&
                fetchedMessage.file_paths.length > 0
            ) {
              const attachmentNames = fetchedMessage.file_paths.map((file) => {
                const pathParts = file.path.split("/");
                return pathParts[pathParts.length - 1];
              });
              setAttachments(attachmentNames);
            } else {
              setAttachments([]);
            }
          })
          .catch((error) => {
            toast.error("Error fetching data", error.data);
          })
          .finally(() => {
            setLoading(false);
          });
    }
  }, [messageId]);
  const escapeHTML = (body) => {
    return body.replace(/<[^>]+>/g, "");
  };

  const getExtension = (fileName) => {
    const parts = fileName.split(".");
    if (parts.length >= 2) {
      const extension = parts[parts.length - 1];
      return extension.toLowerCase();
    } else {
      return "";
    }
  };
  const filteredAttachments = attachments.filter(attachment => attachment !== '');

  return (
    <div className={`message-selected flex-col h-full  p-9`}>
      {loading ? (
        <div className="flex flex-row justify-center items-center mt-10">
          <Hourglass
            visible={true}
            height="40"
            width="40"
            animationDuration="2"
            ariaLabel="hourglass-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={["#306cce", "#72a1ed"]}
          />
        </div>
      ) : (
        <>
          {messages.map((message) => (
            <div
              key={message.id}
              id={`${message.id}`}
              className={`message-content ${selectedTab === message.id ? "block" : "hidden"} opacity-100 transition-opacity duration-150 ease-linear`}
              role="tabpanel"
              aria-labelledby={`tabs-${message.id}`}
            >
              <div className="header space-y-6">
                <div className="header-sb-mail flex flex-row justify-between">
                  <p className="text-bodyLM text-black">{message.label}</p>
                  <ButtonEdit
                    textButton="Edit Message"
                    className="text-primary cursor-pointer"
                    onClick={() => handleEditMessage(message.id)}
                  />
                </div>
                <div className="header-to-mail pb-2 border-b-2 flex items-center space-y-2 flex-row space-x-2">
                  <p className="text-bodySR text-black">To:</p>
                  <p className="email-address border rounded-xl border-dark5  py-[4px] px-[10px] flex flex-row justify-center gap-2 text-bodySR text-dark5 ">
                    {message.recipient_email}
                  </p>
                </div>
              </div>
              <div className="content-mail pt-6">
                <textarea
                  id="body"
                  name="body"
                  readOnly={true}
                  style={{ resize: "none" }}
                  className="body w-full h-[250px] rounded-lg border-0 focus:ring-transparent focus:ring-offset-0 focus:ring-0 focus:border-gray2"
                  value={escapeHTML(message.body)}
                ></textarea>
              </div>
              {filteredAttachments.length > 0 && (
                <div key={'#' + message.id} className={`attachments mt-10 mobile:bg-white ${filteredAttachments.length === 0 ? 'desktop:bg-white' : 'desktop:bg-gray2'} rounded-lg space-x-2 py-5`}>
                  <div className="flex flex-row space-x-6">
                    {filteredAttachments.map((attachment, index) => (
                      <div className="cursor-pointer">
                        {["doc", "docx", "txt", "pdf"].includes(
                          getExtension(attachment),
                        ) ? (
                          <div className="flex flex-row items-center">
                            <DocumentIcon
                              className="mobile:hidden"
                              dimensions={{ width: 50, height: 50 }}
                            />
                            <DocumentIcon
                              className="desktop:hidden"
                              dimensions={{ width: 34, height: 34 }}
                            />
                            <p className="text-bodySR text-dark3">{attachment}</p>
                          </div>
                        ) : null}

                        {["jpg", "jpeg", "png"].includes(getExtension(attachment)) ? (
                          <div className="flex flex-row items-center">
                            <ImageIcon
                              className="mobile:hidden"
                              dimensions={{ width: 50, height: 50 }}
                            />
                            <ImageIcon
                              className="desktop:hidden"
                              dimensions={{ width: 34, height: 34 }}
                            />
                            <p className="text-bodySR text-dark3">{attachment}</p>
                          </div>
                        ) : null}

                        {["mp4"].includes(getExtension(attachment)) ? (
                          <div className="flex flex-row items-center">
                            <MovieIcon
                              className="mobile:hidden"
                              dimensions={{ width: 106, height: 90 }}
                            />
                            <MovieIcon
                              className="desktop:hidden"
                              dimensions={{ width: 34, height: 34 }}
                            />
                            <p className="text-bodySR text-dark3">{attachment}</p>
                          </div>
                        ) : null}

                        {["zip"].includes(getExtension(attachment)) ? (
                          <div className="flex flex-row items-center">
                            <ZipDir
                              className="mobile:hidden"
                              dimensions={{ width: 106, height: 90 }}
                            />
                            <ZipDir
                              className="desktop::hidden"
                              dimensions={{ width: 34, height: 34 }}
                            />
                            <p className="text-bodySR text-dark3">{attachment}</p>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

          ))}

        </>
      )}
    </div>
  );
};

export default MessageSelected;
