import React, { useState } from "react";
import axiosConfig from "../axiosConfig";
import { toast, ToastContainer } from "react-toastify";
import ModalAttachFiles from "../components/modal/modalAttachFiles";
import ButtonApprove from "../components/buttons/ButtonApprove";
import ButtonAttach from "../components/buttons/ButtonAttach";
import { useNavigate } from "react-router-dom";
import { Hourglass } from "react-loader-spinner";

const CreateNewMessage = () => {
  const [isAttachOpen, setIsAttachOpen] = useState(false);
  const [filePaths, setFilePaths] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleFolderSelect = (selectedFolderPaths) => {
    setFilePaths(selectedFolderPaths.join(","));
  };
  const handleApproveClick = () => {
    const label = document.getElementById("label").value;
    const subject = document.getElementById("subject").value;
    const recipientEmail = document.getElementById("recipient_email").value;
    const body = document.getElementById("body").value;
console.log(filePaths);
    setLoading(true);
    axiosConfig
      .post("outbox/create", {
        label: label,
        subject: subject,
        recipient_email: recipientEmail,
        body: body,
        file_paths: filePaths + ',',
      })
      .then((response) => {
        toast.success("Message create with success");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch((error) => {
        toast.error("An error occurred while creating the message", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleAttachButtonClick = () => {
    setIsAttachOpen(true);
  };

  const handleCloseAttachModal = () => {
    setIsAttachOpen(false);
  };
  return (
    <>
      {loading ? (
        <div className="flex flex-row justify-center items-center mt-10">
          <Hourglass
            visible={true}
            height="40"
            width="40"
            animationDuration="2"
            ariaLabel="hourglass-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={["#306cce", "#72a1ed"]}
          />
        </div>
      ) : (
        <>
          <div className="desktop:hidden flex flex-row justify-between bg-white p-6 cursor-pointer">
            <a
              className="flex flex-row items-center space-x-2"
              href="/ui/dashboard"
            >
              <img src="/ui/back.svg" alt="back" />
              <p className="text-primary text-bodyMM">Back</p>
            </a>
            <ButtonApprove
              className="desktop:hidden"
              onClick={handleApproveClick}
            />
          </div>
          <div className="desktop:w-[75%] desktop:h-[95%] left-0 right-0 mx-auto desktop:bg-white mobile:bg-gray2 rounded-2xl">
            <div className={`message-new flex-col h-full p-9`}>
              <div className="header space-y-6">
                <div className="header-sb-mail flex-row justify-between hidden">
                  <p className="text-bodyLM text-black"></p>
                  <p className="text-bodyMM text-dark5">Cc Bcc</p>
                </div>
                <div className="header-to-mail border-b-2 flex flex-row  items-center space-x-2">
                  <label className="text-bodySR text-black">Name:</label>
                  <input
                    type="text"
                    id="label"
                    className="w-full mobile:bg-gray2 text-bodySR text-dark5 border-0 focus:ring-transparent focus:ring-offset-0 focus:ring-0 focus:shadow-none"
                  ></input>
                </div>
                <div className="header-to-mail border-b-2 flex flex-row  items-center space-x-2">
                  <label className="text-bodySR text-black">To:</label>
                  <input
                    type="email"
                    id="recipient_email"
                    className="mobile:bg-gray2 email-address w-full text-bodySR text-dark5 border-0 focus:ring-transparent focus:ring-offset-0 focus:ring-0 focus:shadow-none"
                  ></input>
                </div>
                <div className="header-to-mail border-b-2 flex flex-row items-center space-x-2">
                  <label className="text-bodySR text-black">Subject:</label>
                  <input
                    type="text"
                    id="subject"
                    className="subject mobile:bg-gray2 w-full text-bodySR text-dark5 border-0 focus:ring-transparent focus:ring-offset-0 focus:ring-0 focus:shadow-none"
                  ></input>
                </div>
              </div>
              <div className="flex w-full mt-5">
                <textarea
                  id="body"
                  className=" mobile:bg-gray2 body w-full h-[250px] border-2 rounded-lg border-gray2 focus:ring-transparent focus:ring-offset-0 focus:ring-0 focus:border-gray2"
                ></textarea>
              </div>
              <div className="footer flex flex-row justify-between  mt-10">
                <div className="flex flex-row gap-5">
                  <ButtonApprove
                    className="mobile:hidden"
                    onClick={handleApproveClick}
                  />
                  <ButtonAttach onClick={handleAttachButtonClick} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isAttachOpen && (
        <ModalAttachFiles
          titleModal="Attach a file"
          onSelectFolders={handleFolderSelect}
          closeModal={handleCloseAttachModal}
        />
      )}
      <ToastContainer
        position="top-center"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default CreateNewMessage;
