import React from "react";

const MovieIcon = () => {
  return (
    <svg
      width="106"
      height="90"
      viewBox="0 0 106 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0496 34.5736C20.0496 34.1325 20.0064 33.7021 19.9094 33.2933L96.0151 16.488L93.836 6.67598C93.2535 4.04007 90.6429 2.38321 88 2.96418L15.5728 18.941C12.9299 19.522 11.2686 22.1256 11.8511 24.7615L12.7249 28.71C10.028 29.3018 7.99998 31.7117 7.99998 34.5736C7.99998 37.8873 10.6969 40.577 14.0194 40.577V81.17C14.0194 84.473 16.7055 87.1519 20.0172 87.1519H92.0021C95.3139 87.1519 98 84.473 98 81.17V34.5736H20.0496ZM11.4412 34.5736C11.4412 33.3363 12.315 32.3035 13.4692 32.0453C13.6526 32.0022 13.836 31.9807 14.0302 31.9807C15.2707 31.9807 16.3171 32.8629 16.5652 34.0249C16.5976 34.197 16.6192 34.3799 16.6192 34.5628C16.6192 35.9938 15.4541 37.1449 14.0302 37.1449C12.6062 37.1557 11.4412 35.9937 11.4412 34.5736ZM72.6925 8.95684L87.0075 5.8045L81.9374 16.8L67.6224 19.9523L72.6925 8.95684ZM48.7659 14.2287L63.0809 11.0763L58.0108 22.0718L43.6958 25.2242L48.7659 14.2287ZM24.85 19.5112L39.165 16.3589L34.0949 27.3544L19.7799 30.5068L24.85 19.5112ZM59.6828 37.3063L67.0075 46.957H52.3473L45.0226 37.3063H59.6828ZM35.1844 37.3063L42.5091 46.957H27.849L20.5243 37.3063H35.1844ZM88.2804 75.64C88.2804 78.2544 86.1553 80.3739 83.5339 80.3739H28.4962C25.8748 80.3739 23.7497 78.2544 23.7497 75.64V57.6728C23.7497 55.0584 25.8748 52.9389 28.4962 52.9389H83.5339C86.1553 52.9389 88.2804 55.0584 88.2804 57.6728V75.64ZM76.8457 46.9462L69.521 37.2956H84.1812L91.5059 46.9462H76.8457Z"
        fill="#CED4DA"
      />
    </svg>
  );
};

export default MovieIcon;
