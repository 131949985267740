import React from "react";

const FullFolder = ({ dimensions, className }) => {
  const { width, height } = dimensions;
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 10.4113V3.51322C0 1.57213 1.44976 0 3.23978 0H10.2001C10.8843 0 11.5537 0.236621 12.1085 0.673769L15.1375 3.06805H32.7602C34.5502 3.06805 36 4.64018 36 6.58128V10.4073H0V10.4113Z"
        fill="#FBB03B"
      />
      <path
        opacity="0.1"
        d="M33.0817 19.6354H3.59824C2.59968 19.6354 1.78973 18.7571 1.78973 17.6743V6.28439C1.78973 5.20155 2.59968 4.32324 3.59824 4.32324H33.0817C34.0803 4.32324 34.8902 5.20155 34.8902 6.28439V17.6743C34.8902 18.7571 34.0803 19.6354 33.0817 19.6354Z"
        fill="black"
      />
      <path
        d="M32.7121 20.0768H3.22863C2.23007 20.0768 1.42012 19.1985 1.42012 18.1157V6.7258C1.42012 5.64295 2.23007 4.76465 3.22863 4.76465H32.7121C33.7106 4.76465 34.5206 5.64295 34.5206 6.7258V18.1157C34.5206 19.1985 33.7106 20.0768 32.7121 20.0768Z"
        fill="white"
      />
      <path
        d="M32.7602 6.62939H3.23978C1.44976 6.62939 0 8.20152 0 10.1426V28.4868C0 30.4279 1.44976 32 3.23978 32H32.7602C34.5502 32 36 30.4279 36 28.4868V10.1426C36 8.20152 34.5502 6.62939 32.7602 6.62939Z"
        fill="#FFCC00"
      />
    </svg>
  );
};

export default FullFolder;
