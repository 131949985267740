import { useNavigate } from "react-router-dom";
export default function ButtonPrimary({
  text,
  onClick,
  width,
  padding,
  fontsize,
}) {
  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <button
      className={`border ${width} rounded-md bg-primary color-white ${padding}`}
      onClick={handleClick}
    >
      <p className={`text-white ${fontsize}`}>{text}</p>
    </button>
  );
}
