import React, { createContext, useContext, useState } from "react";
import axiosConfig from "../axiosConfig";
import { loginFields } from "../constants/formFields";

const AuthContext = createContext({
  accessToken: () => {},
});

// Provider for Auth
export const AuthProvider = ({ children }) => {
  const [loginState] = useState(loginFields); //State for login

  const accessToken = async () => {
    return await axiosConfig.post("login", {
      email: loginState[0].value,
      password: loginState[1].value,
    });
  };

  const authValues = {
    accessToken,
  };

  return (
    <AuthContext.Provider value={authValues}>{children}</AuthContext.Provider>
  );
};
export const useAuth = () => useContext(AuthContext); //create hook for the use authContext
