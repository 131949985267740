import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const NotFoundPage = () => {

  return (
    <div>
      <Header />
      <div className="mobile:flex-col desktop:flex-row py-32 mx-6 space-y-4">
        <p className="text-primary text-center">404 Error</p>
        <h1 className="text-h2 text-center">Page not found</h1>
        <p className="text-dark5 text-center">
          Sorry, the page you are looking for could not be found or has been
          removed.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default NotFoundPage;
